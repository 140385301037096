import { AlertifyService } from 'app/services/alertify.service';
import { AlertModel } from 'app/model/AlertModel';
import { AuthService } from 'app/services/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OdemeTur } from 'app/model/OdemeTur';
import { ModuleType } from 'app/model/ModuleType';
import { Router } from '@angular/router';
import { DomainInfoView } from 'app/model/DomainInfoView';

@Component({
  selector: 'app-ParamtrinkHesabi',
  templateUrl: './ParamtrinkHesabi.component.html',
  styleUrls: ['./ParamtrinkHesabi.component.css']
})
export class ParamtrinkHesabiComponent implements OnInit {
  @ViewChild('contentTur') contentTur: ModalDirective;
  private modalRef: NgbModalRef;
  loadPage: boolean = false;
  odemeTurEdit: OdemeTur;

  constructor(private alertifyService: AlertifyService, private authService: AuthService, private modalService: NgbModal, private router: Router) { }
  domainInfo: DomainInfoView;
  moduleAdi: string = "paramtrink";
  moduleType: ModuleType = ModuleType.SanalPos;
  TOKEN_ROLES: string[] = [];


  ngOnInit() {
    this.domainInfoLoad();
    this.TOKEN_ROLES = this.authService.getCurrentUserRoles();
  }
  metod(event: AlertModel) {
    if (event.status == true) {
      if (event.eventType == 1) {
        this.alertifyService.success("İşleminiz Kayıt Edilmiştir");
      } else if (event.eventType == 3) {
        this.alertifyService.success("İşleminiz Güncellenmiştir");
      } else if (event.eventType == 2) {
        this.alertifyService.success("İşleminiz Silinmiştir");
      }
      if (event.eventType == 4) {
        this.alertifyService.success(event.message);
      }
    }
    else {
      if (event.eventType == 1) {
        this.alertifyService.error("İşleminiz Kayıt Edilememiştir");
      } else if (event.eventType == 3) {
        this.alertifyService.error("İşleminiz Güncellenmemştir");
      } else if (event.eventType == 2) {
        this.alertifyService.error("İşleminiz Silenememiştir");
      } else if (event.eventType == 4) {
        this.alertifyService.error(event.message);
      }
      else if (event.eventType == 5) {
        this.alertifyService.error("Zorunlu Alanları Eksiksiz Doldurun");
      }
    }
  }
  odemeTurIslem(odemeTur: OdemeTur) {
    this.loadPage = false;
    this.odemeTurEdit = odemeTur;
    this.modalRef = this.modalService.open(this.contentTur, { size: "lg" });
  }

  odemeTurIslemResult(sonuc: boolean) {
    if (sonuc == true) {
      this.alertifyService.success("İşlem Başarılı");
      this.loadPage = true;
    }
    else {
      this.alertifyService.error("İşlem Başarısız");
    }
    this.modalRef.close();
  }

  domainInfoLoad() {
    this.authService.GetDomainInfo().subscribe(data => {
      this.domainInfo = data;
    })
  }
  paramtrinkVarmi() {
    return this.domainInfo.modules.find(x => x.type == ModuleType.SanalPos);
  }

  isAuthorized() {
    return this.TOKEN_ROLES.includes("Owner") || this.TOKEN_ROLES.includes("Admin") || this.TOKEN_ROLES.includes("System");
  }

}
