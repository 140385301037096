import { Component, OnInit } from '@angular/core';
import {  FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertifyService } from 'app/services/alertify.service';
import { AuthService } from 'app/services/auth.service';
import { UserInfoView } from 'app/model/UserInfoView';
@Component({
  selector: 'app-kullaniciBilgileri',
  templateUrl: './kullaniciBilgileri.component.html',
  styleUrls: ['./kullaniciBilgileri.component.css']
})
export class KullaniciBilgileriComponent implements OnInit {
  userInfoForm:FormGroup;
  userInfo:UserInfoView=new UserInfoView();

  constructor(private formBuilder: FormBuilder, private alertifyService: AlertifyService, private authService: AuthService) { }

  ngOnInit() {
    this.createUserInfoForm();
    this.authService.getUserInfo().subscribe(data=>{
      this.userInfo=data;
    });
  }

  createUserInfoForm() {
    this.userInfoForm = this.formBuilder.group({
      username: [""],
      name: [""],
      email: [""],
      cep: [""],
      tcKimlik:[""]
        });
                        }
  setUserInfo()
  {
    if (this.userInfoForm.valid)
     {
      this.userInfo = Object.assign({},this.userInfoForm.value) ;
     }
  }  
}
