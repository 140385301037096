import { ErrorHandler, Injectable, Injector } from "@angular/core";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";

//import * as StackTraceParser from 'error-stack-parser';
import { AlertifyService } from "../services/alertify.service";

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(
    private injector: Injector,
    private notificationService: AlertifyService
  ) { }

  handleError(error: Error | HttpErrorResponse) {
    //şimdilik hata sadece alert olarak verildi.
    //hataların toplandığı bir yer ve local veritabanı yapılacak.
    //hatalar takip edilerek merkeze gönderme, ya da çok sık karşılaşılan hatalar için düzenlemeler yapılacak.
    //örneğin bir ayarın varlığını her yerde sormamaıza gerek yok buraya gelirse mesaj içeriğine göre ayarlara yönlendirme falan yapacağız.

    //    const notificationService = this.injector.get(NotificationService);
    //const router = this.injector.get(Router);

    if (error instanceof HttpErrorResponse) {
      // Server error happened
      if (!navigator.onLine) {
        // No Internet connection
        return this.notificationService.error("İnternet bağlantınız kesildi. Lütfen kontrol ediniz.");
      }

      if (error.status == 0) {
        return this.notificationService.error("Servis bağlantınız kesildi. Lütfen kontrol ediniz.");
      }
      else if (error.status == 401) {
        return this.notificationService.error("Bu işlemi yapmak için oturum açmanız gereklidir.");
      }
      else if (error.status == 403) {
        return this.notificationService.error("Bu işlemi yapmak için yetkiniz yok.");
      }

      // Http Error
      if (error.error == null) {
        this.notificationService.error(`${error.status} - ${error.message}`);
      } else {
        this.notificationService.error(error.error);
      }
    }
    //ngx-datatable üzerinde modal açıldığında table üzerinde ki focus yüzünden bu hatayı veriyor.Bu hatayı basmıyoruz herhangi biryere
    else if (new String(error).includes("ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. ")) { }
    else {
      // Client Error Happend
      this.notificationService.error(error.message);
    }
    // Log the error anyway
    console.error(error);
  }
}


