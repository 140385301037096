<app-loadingScreen *ngIf="!domainInfo"></app-loadingScreen>
<div *ngIf="domainInfo">
  <div *ngIf="!smsVarmi()">
    <app-buyModule [moduleAdi]="moduleAdi"></app-buyModule>
  </div>
  <div *ngIf="smsVarmi()">
    <ng-template [ngIf]="!isAuthorized()">
      <app-accessDenied></app-accessDenied>
    </ng-template>

    <ng-template [ngIf]="isAuthorized()">
      <tbm-smsHesapBilgi></tbm-smsHesapBilgi>
    </ng-template>

  </div>
</div>
