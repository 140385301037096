import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertModel } from 'app/model/AlertModel';
import { ApplicationType } from 'app/model/ApplicationType.enum';
import { Banka } from 'app/model/Banka';
import { DomainInfoView } from 'app/model/DomainInfoView';
import { AlertifyService } from 'app/services/alertify.service';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-bankaHesapDuzenle',
  templateUrl: './bankaHesapDuzenle.component.html',
  styleUrls: ['./bankaHesapDuzenle.component.css']
})
export class BankaHesapDuzenleComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private alertifyService: AlertifyService,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal) { }

  bankaOnlineHesapId: number = 0;
  domainInfo: DomainInfoView;
  uygulama = ApplicationType.Paramtrink;
  modalRef: NgbModalRef;
  seffafV2Banka: Banka = new Banka();

  @ViewChild('contentSeffafV2BankaListesi') contentSeffafV2BankaListesi: ModalDirective;

  ngOnInit() {
    this.getDomainInfo();
    this.getActivatedRoute();
  }

  getDomainInfo() {
    this.authService.GetDomainInfo().subscribe(data => {
      this.domainInfo = data;
    });
  }


  getActivatedRoute() {
    this.activatedRoute.params.subscribe(param => {
      this.bankaOnlineHesapId = param["bankaOnlineHesapId"];
    });
  }

  eventAlert(alert: AlertModel) {
    if (alert.status == true) {
      if (alert.eventType == 1) {
        this.alertifyService.success("İşleminiz Kayıt Edilmiştir");
      } else if (alert.eventType == 3) {
        this.alertifyService.success("İşleminiz Güncellenmiştir");
      } else if (alert.eventType == 2) {
        this.alertifyService.success("İşleminiz Silinmiştir");
      } else if (alert.eventType == 4) {
        this.alertifyService.success(alert.message);
      }
    } else {
      if (alert.eventType == 1) {
        this.alertifyService.error(alert.message);
      } else if (alert.eventType == 3) {
        this.alertifyService.error(alert.message);
      } else if (alert.eventType == 2) {
        this.alertifyService.error(alert.message);
      } else if (alert.eventType == 4) {
        this.alertifyService.error(alert.message);
      }
      else if (alert.eventType == 5) {
        this.alertifyService.error("Zorunlu Alanları Eksiksiz Doldurun");
      }
    }
  }

  eventReturnBankaHesapList() {
    this.router.navigateByUrl("/bankaHesapListesi");
  }

  eventOpenSeffafV2BankaListesi() {
    this.modalRef = this.modalService.open(this.contentSeffafV2BankaListesi, { size: 'lg' });
  }

  eventCloseSeffafV2BankaListesi(seffafV2Banka: Banka) {
    this.seffafV2Banka = seffafV2Banka;
    this.modalRef.close();
  }


}
