export enum ApplicationType {
    SistemCore = 99, 
       
    EhliyetAdresi = 1,
    StkAdresi = 2,
    Paramtrink = 3, 
    TabimWeb = 4, 
    WebSite = 5,
    
    EhliyetAdresiPortal = 51,
    SurucuEtl = 91,
}
