<app-loadingScreen *ngIf="!domainInfo"></app-loadingScreen>
<div *ngIf="domainInfo">

  <section id="striped-row-form-layouts">
    <div class="container-fluid">
      <div class="row text-left">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="px-3">
                <ng-template [ngIf]="!isAuthorized()">
                  <app-accessDenied></app-accessDenied>
                </ng-template>

                <ng-template [ngIf]="isAuthorized()">
                  <div *ngIf="webSayfasiVarmi()">
                    <tbm-emailAyarlari [baslik]="'Web Sayfası'" [copiedEmailHesap]="copiedEmailHesap"
                      [moduleType]="moduleTypeWebSayfasi" (eventBind)="eventAlert($any($event))"
                      (eventCopyEmailHesap)="eventCopyEmailHesap($any($event))">
                    </tbm-emailAyarlari>
                  </div>

                  <div *ngIf="sanalPosVarmi()">
                    <tbm-emailAyarlari [baslik]="'Paramtrink'" [copiedEmailHesap]="copiedEmailHesap"
                      [moduleType]="moduleTypeSanalPos" (eventBind)="eventAlert($any($event))"
                      (eventCopyEmailHesap)="eventCopyEmailHesap($any($event))">
                    </tbm-emailAyarlari>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>
