import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertifyService } from 'app/services/alertify.service';

@Component({
  selector: 'app-OdemeDetay',
  templateUrl: './OdemeDetay.component.html',
  styleUrls: ['./OdemeDetay.component.css']
})
export class OdemeDetayComponent implements OnInit {
  istekId:number;

  constructor(private activatedRoute:ActivatedRoute,private alertifyService:AlertifyService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.istekId=params["id"]; 
    });
  }

  eventAlert(data) {
    if (data.status == true) {
      if (data.eventType == 1) {
        this.alertifyService.success("İşleminiz Kayıt Edilmiştir");
      } else if (data.eventType == 3) {
        this.alertifyService.success("İşleminiz Güncellenmiştir");
      } else if (data.eventType == 2) {
        this.alertifyService.success("İşleminiz Silinmiştir");
      } else if (data.eventType == 4) {
        this.alertifyService.success(data.message);
      }
    } else {
      if (data.eventType == 1) {
        this.alertifyService.error("İşleminiz Kayıt Edilememiştir");
      } else if (data.eventType == 3) {
        this.alertifyService.error("İşleminiz Güncellenmemştir");
      } else if (data.eventType == 2) {
        this.alertifyService.error("İşleminiz Silenememiştir");
      } else if (data.eventType == 4) {
        this.alertifyService.error(data.message);
      }
      else if (data.eventType == 5) {
        this.alertifyService.error("Zorunlu Alanları Eksiksiz Doldurun");
      }
    }
  }

}
