import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertModel } from 'app/model/AlertModel';
import { DomainInfoView } from 'app/model/DomainInfoView';
import { ModuleType } from 'app/model/ModuleType';
import { AlertifyService } from 'app/services/alertify.service';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-bankaHesapListesi',
  templateUrl: './bankaHesapListesi.component.html',
  styleUrls: ['./bankaHesapListesi.component.css']
})
export class BankaHesapListesiComponent implements OnInit {

  moduleAdi: string = "Online Banka";
  domainInfo: DomainInfoView;
  TOKEN_ROLES: string[] = [];

  constructor(
    private alertifyService: AlertifyService,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit() {
    this.getDomainInfo();
  }

  getDomainInfo() {
    this.authService.GetDomainInfo().subscribe(data => {
      this.domainInfo = data;
    });

    this.TOKEN_ROLES = this.authService.getCurrentUserRoles();
  }

  moduleVarmi() {
    return this.domainInfo.modules.find(x => x.type == ModuleType.BankaOnline);
  }

  eventEditedBankaOnline(bankaHesapId: number) {
    this.router.navigateByUrl('bankaHesapDuzenle/' + bankaHesapId);
  }

  eventHareketlerListBankaOnline(bankaHesapId: number) {
    this.router.navigateByUrl('bankaHareketListesi/' + bankaHesapId);
  }

  eventSorgularListBankaOnline(bankaHesapId: number) {
    this.router.navigateByUrl('bankaSorguListesi/' + bankaHesapId);
  }

  eventAlert(alert: AlertModel) {
    if (alert.status == true) {
      if (alert.eventType == 1) {
        this.alertifyService.success("İşleminiz Kayıt Edilmiştir");
      } else if (alert.eventType == 3) {
        this.alertifyService.success("İşleminiz Güncellenmiştir");
      } else if (alert.eventType == 2) {
        this.alertifyService.success("İşleminiz Silinmiştir");
      } else if (alert.eventType == 4) {
        this.alertifyService.success(alert.message);
      }
    } else {
      if (alert.eventType == 1) {
        this.alertifyService.error(alert.message);
      } else if (alert.eventType == 3) {
        this.alertifyService.error(alert.message);
      } else if (alert.eventType == 2) {
        this.alertifyService.error(alert.message);
      } else if (alert.eventType == 4) {
        this.alertifyService.error(alert.message);
      }
      else if (alert.eventType == 5) {
        this.alertifyService.error("Zorunlu Alanları Eksiksiz Doldurun");
      }
    }
  }

  isAuthorized() {
    return this.TOKEN_ROLES.includes("Owner") || this.TOKEN_ROLES.includes("Admin") || this.TOKEN_ROLES.includes("System") ||
    this.TOKEN_ROLES.includes("BankaOnline_Admin") || this.TOKEN_ROLES.includes("BankaOnline_Viewer") || this.TOKEN_ROLES.includes("BankaOnline_Operator");
  }


}
