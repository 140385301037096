import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-Makbuz',
  templateUrl: './Makbuz.component.html',
  styleUrls: ['./Makbuz.component.css']
})
export class MakbuzComponent implements OnInit {
  istekId:number;
  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.istekId=params["id"]; 
    });
  }
}
