import { TasarimParametre } from "./TasarimParametre";
import { Tema } from "./Tema";
import { DomainWebParametre } from "./DomainWebParametre";
export class Tasarim {
  id: number;
  temaId: number;
  ad: string;
  domainName: string;
  tema: Tema = new Tema();
  tasarimParametre: TasarimParametre[] = new Array<TasarimParametre>();
  domainWebParametre: DomainWebParametre = new DomainWebParametre();
}
