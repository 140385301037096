import { TemaGrup } from "./TemaGrup";
export class Tema {

    id:number;
    ad:string;
    aciklama:string;
    domainName:string;
    temaGrup:TemaGrup[]=new Array<TemaGrup>();

}
