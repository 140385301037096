import { Component, OnInit } from '@angular/core';
import { AlertifyService } from 'app/services/alertify.service';
import { AuthService } from 'app/services/auth.service';
import { DomainInfoView } from 'app/model/DomainInfoView';
import { ModuleType } from 'app/model/ModuleType';


@Component({
  selector: 'app-SmsGonder',
  templateUrl: './SmsGonder.component.html',
  styleUrls: ['./SmsGonder.component.css']
})
export class SmsGonderComponent implements OnInit {

  constructor(private alertifyService: AlertifyService, private authService: AuthService) { }

  domainInfo: DomainInfoView;
  moduleAdi: string = "SMS";
  TOKEN_ROLES: string[] = [];
  ngOnInit() {
    this.authService.GetDomainInfo().subscribe(data => {
      this.domainInfo = data;
    });
    this.TOKEN_ROLES = this.authService.getCurrentUserRoles();
  }

  smsVarmi() {
    return this.domainInfo.modules.find(x => x.type == ModuleType.Iletisim);
  }

  isAuthorized() {
    return this.TOKEN_ROLES.includes("Owner") || this.TOKEN_ROLES.includes("Admin") || this.TOKEN_ROLES.includes("System") || this.TOKEN_ROLES.includes("Iletisim_Admin") || this.TOKEN_ROLES.includes("Iletisim_Operator");
  }

  eventAlert(data:any) {
    if (data.status == true) {
      if (data.eventType == 1) {
        this.alertifyService.success("İşleminiz Kayıt Edilmiştir");
      } else if (data.eventType == 3) {
        this.alertifyService.success("İşleminiz Güncellenmiştir");
      } else if (data.eventType == 2) {
        this.alertifyService.success("İşleminiz Silinmiştir");
      } else if (data.eventType == 4) {
        this.alertifyService.success("İşleminiz yapılmıştır");
      }
    } else {
      if (data.eventType == 1) {
        this.alertifyService.error("İşleminiz Kayıt Edilememiştir");
      } else if (data.eventType == 3) {
        this.alertifyService.error("İşleminiz Güncellenmemştir");
      } else if (data.eventType == 2) {
        this.alertifyService.error("İşleminiz Silenememiştir");
      } else if (data.eventType == 4) {
        this.alertifyService.error(data.message);
      }
      else if (data.eventType == 5) {
        this.alertifyService.error("Zorunlu Alanları Eksiksiz Doldurun");
      }
    }
  }
}
