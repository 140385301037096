import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment.prod';
import { ModuleType } from 'app/model/ModuleType';
import { AuthService } from 'app/services/auth.service';
import { DomainInfoView } from 'app/model/DomainInfoView';
import { AlertifyService } from 'app/services/alertify.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ParamtrinkRouteTur } from 'app/model/ParamtrinkRouteTur.enum';
import { AlertModel } from 'app/model/AlertModel';



@Component({
  selector: 'app-OdemeHazirla',
  templateUrl: './OdemeHazirla.component.html',
  styleUrls: ['./OdemeHazirla.component.css']
})
export class OdemeHazirlaComponent implements OnInit {


  constructor(
    private authService: AuthService,
    private alertifyService: AlertifyService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
  }


  paramtrinkRouteTur: ParamtrinkRouteTur;
  odemePath = environment.paramtrinkPath + "Odeme/OdemeAl";

  ngOnInit() {
    this.domainInfoLoad();
    this.getActivatedRoute();
  }

  getActivatedRoute() {
    this.activatedRoute.params.subscribe(data => {
      this.paramtrinkRouteTur = data["turId"];
    });
  }

  domainInfo: DomainInfoView;
  domainId: number;
  moduleAdi: string = "paramtrink";
  TOKEN_ROLES: string[] = [];

  domainInfoLoad() {
    this.authService.GetDomainInfo().subscribe(data => {
      this.domainInfo = data;
    });
    this.TOKEN_ROLES = this.authService.getCurrentUserRoles();
  }

  paramtrinkVarmi() {
    return this.domainInfo.modules.find(x => x.type == ModuleType.SanalPos);
  }

  eventAlert(alert: AlertModel) {
    if (alert.status == true) {
      if (alert.eventType == 1) {
        this.alertifyService.success("İşleminiz Kayıt Edilmiştir");
      } else if (alert.eventType == 3) {
        this.alertifyService.success("İşleminiz Güncellenmiştir");
      } else if (alert.eventType == 2) {
        this.alertifyService.success("İşleminiz Silinmiştir");
      } else if (alert.eventType == 4) {
        this.alertifyService.success(alert.message);
      }
    } else {
      if (alert.eventType == 1) {
        this.alertifyService.error("İşleminiz Kayıt Edilememiştir");
      } else if (alert.eventType == 3) {
        this.alertifyService.error("İşleminiz Güncellenmemştir");
      } else if (alert.eventType == 2) {
        this.alertifyService.error("İşleminiz Silenememiştir");
      } else if (alert.eventType == 4) {
        this.alertifyService.error(alert.message);
      }
    }
  }

  eventReturnOdemeHareketleri() {
    this.router.navigateByUrl("/odeme-hareketleri");
  }

  eventReturnTasarimAyarlari() {
    this.router.navigateByUrl("/epostaHesapBilgisi");
  }

  isAuthorized() {
    return this.TOKEN_ROLES.includes("Owner") || this.TOKEN_ROLES.includes("Admin") || this.TOKEN_ROLES.includes("System") ||
      this.TOKEN_ROLES.includes("SanalPos_Admin") || this.TOKEN_ROLES.includes("SanalPos_Operator");
  }


}
