import { DashboardComponent } from "./dashboard/dashboard.component";
import { Routes } from "@angular/router";
import { OdemeHazirlaComponent } from "./paramtrink/OdemeHazirla/OdemeHazirla.component";
import { OdemeAlComponent } from "./paramtrink/OdemeAl/OdemeAl.component";
import { AuthGuard } from "./services/auth-guard.service";
import { KurumComponent } from "./website/Kurum/Kurum.component";
import { YapimAsamasindaComponent } from "./errors/yapimAsamasinda/yapimAsamasinda.component";
import { OdemeHareketleriComponent } from "./paramtrink/OdemeHareketleri/OdemeHareketleri.component";
import { ParamtrinkHesabiComponent } from "./paramtrink/ParamtrinkHesabi/ParamtrinkHesabi.component";
import { SmsListesiComponent } from './iletisim/SmsListesi/SmsListesi.component';
import { SmsGonderComponent } from './iletisim/SmsGonder/SmsGonder.component';
import { SmsHesapBilgisiComponent } from './iletisim/SmsHesapBilgisi/SmsHesapBilgisi.component';
import { KomisyonOranlariComponent } from './paramtrink/komisyonOranlari/komisyonOranlari.component';
import { ParametreResolve } from './resolvers/parametre.resolve';
import { OdemeDetayComponent } from './paramtrink/OdemeDetay/OdemeDetay.component';
import { MakbuzComponent } from './paramtrink/Makbuz/Makbuz.component';
import { BildirimlerComponent } from "./iletisim/Bildirimler/Bildirimler.component";
import { EpostaHesapBilgisiComponent } from "./website/Kurum/eposta-hesap-bilgisi/eposta-hesap-bilgisi.component";

import { BankaHesapListesiComponent } from "./bankaOnline/bankaHesapListesi/bankaHesapListesi.component";
import { BankaHesapDuzenleComponent } from "./bankaOnline/bankaHesapDuzenle/bankaHesapDuzenle.component";
import { BankaHareketListesiComponent } from "./bankaOnline/bankaHareketListesi/bankaHareketListesi.component";
import { BankaSorguListesiComponent } from "./bankaOnline/bankaSorguListesi/bankaSorguListesi.component";
import { KullanicilarComponent } from "./kullaniciIslemleri/KullaniciListesi/Kullanicilar.component";
import { KullaniciEkleComponent } from "./kullaniciIslemleri/KullaniciEkle/KullaniciEkle.component";



export const appRoutes: Routes = [
  { path: "dashboard", component: DashboardComponent },
  { path: "kurumBilgisi", component: KurumComponent },
  { path: "odemeHazirla/:turId", component: OdemeHazirlaComponent },
  {
    path: "odemeAl", component: OdemeAlComponent,
    resolve: {
      account: ParametreResolve
    }
  },
  { path: "yapimAsamasinda", component: YapimAsamasindaComponent },
  { path: "odeme-hareketleri", component: OdemeHareketleriComponent },
  { path: "paramtrinkHesabi", component: ParamtrinkHesabiComponent },
  { path: "smsListesi", component: SmsListesiComponent },
  { path: "smsGonder", component: SmsGonderComponent },
  { path: "smsHesapBilgisi", component: SmsHesapBilgisiComponent },
  { path: "komisyonOran", component: KomisyonOranlariComponent },
  { path: "odemeDetay/:id", component: OdemeDetayComponent },
  { path: "makbuz/:id", component: MakbuzComponent },
  { path: "bildirimleriGoster", component: BildirimlerComponent },
  { path: "epostaHesapBilgisi", component: EpostaHesapBilgisiComponent },
  { path: "bankaHesapListesi", component: BankaHesapListesiComponent },
  { path: "bankaHesapDuzenle/:bankaOnlineHesapId", component: BankaHesapDuzenleComponent },
  { path: "bankaHareketListesi/:bankaOnlineHesapId", component: BankaHareketListesiComponent },
  { path: "bankaSorguListesi/:bankaOnlineHesapId", component: BankaSorguListesiComponent },
  { path: "kullaniciListesi", component: KullanicilarComponent },
  { path: "kullaniciEkle", component: KullaniciEkleComponent },
  { path: "**", redirectTo: "dashboard", pathMatch: "full" },

];
