<app-loadingScreen *ngIf="!domainInfo"></app-loadingScreen>
<div *ngIf="domainInfo">
  <div *ngIf="!moduleVarmi()">
    <app-buyModule [moduleAdi]="moduleAdi"></app-buyModule>
  </div>
  <div *ngIf="moduleVarmi()">

    <ng-template [ngIf]="!isAuthorized()">
      <app-accessDenied></app-accessDenied>
    </ng-template>

    <ng-template [ngIf]="isAuthorized()">
      <tbm-bankaHesapListesi (alert)="eventAlert($any($event))" (editedBankaOnline)="eventEditedBankaOnline($any($event))"
      (hareketlerListBankaOnline)="eventHareketlerListBankaOnline($any($event))"
      (sorgularListBankaOnline)="eventSorgularListBankaOnline($any($event))">
    </tbm-bankaHesapListesi>
    </ng-template>

  </div>
</div>
