<section id="login">
  <div class="container-fluid">
    <div class="row full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card gradient-custom-dark text-center width-400">
          <div class="card-img mt-2">
            <img alt="element 06" src="assets/image/logo/paramtrink-logo.png" height="70">
          </div>
          <div class="card-body">
            <div class="card-block">
              <h2 class="white">Giriş</h2>
              <form  (ngSubmit)="login()" #loginForm="ngForm">
                <div class="form-group">
                  <div class="col-md-12">
                    <!--<input type="email" class="form-control" name="inputEmail" id="inputEmail" placeholder="Email" ngModel required email>-->
                    <input class="form-control" type="text" name="userName" [(ngModel)]="loginUser.userName" required
                      placeholder="Kullanıcı adı">
                  </div>
                </div>

                <div class="form-group">
                  <div class="col-md-12">
                    <input class="form-control" type="password" name="password" [(ngModel)]="loginUser.password"
                      required placeholder="Şifre">
                    <!--<input type="password" class="form-control" name="inputPass" id="inputPass" placeholder="Password" ngModel required>-->
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0 ml-3">
                        <input type="checkbox" name="rememberMe" class="custom-control-input" checked id="rememberme"
                          [(ngModel)]="loginUser.rememberMe">
                        <label class="custom-control-label float-left white" for="rememberme">Beni hatırla</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="col-md-12">
                    <button class="btn btn-danger btn-block btn-raised" type="submit"
                      [disabled]="!loginForm.valid">Giriş</button>
                    <!--<button type="submit" class="btn btn-pink btn-block btn-raised">Submit</button>
                                      <button type="button" class="btn btn-secondary btn-block btn-raised">Cancel</button>-->
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left"><a (click)="onForgotPassword()" class="white">Şifremi unuttum!</a></div>
            <div class="float-right"><a (click)="onRegister()" class="white">Kaydol</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
