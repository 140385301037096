import { HttpInterceptor, HttpRequest, HttpHandler,HttpEvent } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes('/auth/')) {
           return next.handle(request);
        }
        request = request.clone({
            setHeaders: {
                Accept:"application/json",
                "Access-Control-Allow-Origin":"*",
                "Access-Control-Allow-Methods":"GET,POST,DELETE,PUT,OPTIONS,HEAD",
                Authorization: `Bearer ${this.auth.token}`,
            }
        });
        return next.handle(request);
    }
}

