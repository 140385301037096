import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginUser } from 'app/model/LoginUser';
import { AuthService } from 'app/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute) { }

  @Output() handleForgotPassword = new EventEmitter();
  @Output() handleRegister = new EventEmitter();

  loginUser: LoginUser = new LoginUser();

  paramsSubscription: Subscription;

  ngOnInit() {
    this.getQueryParams();
  }

  getQueryParams() {
    this.paramsSubscription = this.activatedRoute.queryParams.subscribe(param => {
      if (param['userName'] && param['password'] && param['route']) {
        this.loginUser.userName = param['userName'];
        this.loginUser.password = param['password'];
        this.loginUser.redirectUrl = param['route'];
        this.login();
      }
    });
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }

  login() {
    this.authService.login(this.loginUser);
  }

  onForgotPassword() {
    this.handleForgotPassword.emit();
  }

  onRegister() {
    this.handleRegister.emit();
  }

}
