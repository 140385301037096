<app-loadingScreen *ngIf="!domainInfo"></app-loadingScreen>
<div *ngIf="domainInfo">

  <ng-template [ngIf]="isAuthorizedForSanalPos()">
    <div class="row" >
      <div class="col-xl-3 col-lg-6 col-md-6 col-12 d-flex">
        <div class="card bg-dark" style="width: 100%;">
          <div class="card-body p-0">
            <div class="card-block  pt-2 pl-4 pr-4 pb-0">
              <div class="media m-0 p-0">
                <div class="media-body white text-left">
                  <h3 class="font-large-1 m-0 p-0 text-light">{{paramtrinkVeri.gunlukTahsilat}} TL</h3>
                  <p>Paramtrink Günlük Çekim</p>
                </div>
                <div class="media-right white text-right">
                  <i class="zmdi zmdi-comment-alert font-large-1"></i>
                </div>
              </div>
            </div>
            <!--<div id="Widget-line-chart" class="height-75 WidgetlineChart WidgetlineChartShadow mb-3">
             <x-chartist class="" [data]="WidgetlineChart.data" [type]="WidgetlineChart.type"
                [options]="WidgetlineChart.options" [responsiveOptions]="WidgetlineChart.responsiveOptions"
                [events]="WidgetlineChart.events">
              </x-chartist>
            </div>-->
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-6 col-12 d-flex">
        <div class="card bg-danger" style="width: 100%;">
          <div class="card-body p-0">
            <div class="card-block  pt-2 pl-4 pr-4 pb-0">
              <div class="media m-0 p-0">
                <div class="media-body white text-left">
                  <h3 class="font-large-1 m-0 p-0 text-light">{{paramtrinkVeri.aylikTahsilat}} TL</h3>
                  <p>Paramtrink Aylık Çekim({{paramtrinkVeri.sonDonem}})</p>
                </div>
                <div class="media-right white text-right">
                  <i class="zmdi zmdi-file-text font-large-1"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-lg-6 col-md-6 col-12 d-flex">
        <div class="card bg-info" style="width: 100%;">
          <div class="card-body p-0">
            <div class="card-block  pt-2 pl-4 pr-4 pb-0">
              <div class="media m-0 p-0">
                <div class="media-body white text-left">
                  <h3 class="font-large-1 m-0 p-0 text-light">{{paramtrinkVeri.gunlukislem}}</h3>
                  <p>Paramtrink Günlük İşlem</p>
                </div>
                <div class="media-right white text-right">
                  <i class="zmdi zmdi-notifications font-large-1"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-6 col-12 d-flex">
        <div class="card" style="background-color: #BF9D76;width: 100%;">
          <div class="card-body p-0">
            <div class="card-block  pt-2 pl-4 pr-4 pb-0">
              <div class="media m-0 p-0">
                <div class="media-body white text-left">
                  <h3 class="font-large-1 m-0 p-0 text-light">{{paramtrinkVeri.aylik}}</h3>
                  <p>Paramtrink Aylık İşlem({{paramtrinkVeri.sonDonem}})</p>
                </div>
                <div class="media-right white text-right">
                  <i class="zmdi zmdi-calendar-note font-large-1"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <div *ngIf="bankaOnlineModuleVarmi()">
    <ng-template [ngIf]="isAuthorizedForBankaOnline()">
      <div class="row">
        <div class="col-xl-3 col-lg-6 col-md-6 col-12 d-flex">
          <div class="card bg-dark" style="width: 100%;">
            <div class="card-body p-0">
              <div class="card-block  pt-2 pl-4 pr-4 pb-0">
                <div class="media m-0 p-0">
                  <div class="media-body white text-left">
                    <h3 class="font-large-1 m-0 p-0 text-light">{{bankaStatistics.gunlukAlacak}} TL</h3>
                    <p>Banka Günlük Alacak</p>
                  </div>
                  <div class="media-right white text-right">
                    <i class="zmdi zmdi-comment-alert font-large-1"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-12 d-flex">
          <div class="card bg-danger" style="width: 100%;">
            <div class="card-body p-0">
              <div class="card-block  pt-2 pl-4 pr-4 pb-0">
                <div class="media m-0 p-0">
                  <div class="media-body white text-left">
                    <h3 class="font-large-1 m-0 p-0 text-light">{{bankaStatistics.aylikAlacak}} TL</h3>
                    <p>Banka Aylık Alacak({{bankaStatistics.donemAyi}})</p>
                  </div>
                  <div class="media-right white text-right">
                    <i class="zmdi zmdi-file-text font-large-1"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-6 col-md-6 col-12 d-flex">
          <div class="card bg-info" style="width: 100%;">
            <div class="card-body p-0">
              <div class="card-block  pt-2 pl-4 pr-4 pb-0">
                <div class="media m-0 p-0">
                  <div class="media-body white text-left">
                    <h3 class="font-large-1 m-0 p-0 text-light">{{bankaStatistics.gunlukBorc}} TL</h3>
                    <p>Banka Günlük Borç</p>
                  </div>
                  <div class="media-right white text-right">
                    <i class="zmdi zmdi-notifications font-large-1"></i>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-12 d-flex">
          <div class="card" style="background-color: #BF9D76;width: 100%;">
            <div class="card-body p-0">
              <div class="card-block  pt-2 pl-4 pr-4 pb-0">
                <div class="media m-0 p-0">
                  <div class="media-body white text-left">
                    <h3 class="font-large-1 m-0 p-0 text-light">{{bankaStatistics.aylikBorc}} TL</h3>
                    <p>Banka Aylık Borç({{bankaStatistics.donemAyi}})</p>
                  </div>
                  <div class="media-right white text-right">
                    <i class="zmdi zmdi-calendar-note font-large-1"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
