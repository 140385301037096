import { Injectable } from '@angular/core';
import { OdemeTalep } from '../model/OdemeTalep';

@Injectable({
  providedIn: 'root'
})
export class PassOdemeTalepService {
  public odemeTalep:OdemeTalep;
  constructor() { }
  setDefaultOdemeTalep(){
    this.odemeTalep=new OdemeTalep();
    this.odemeTalep.TalepNo = "Paramtrink";
    this.odemeTalep.Aciklama = "";
    this.odemeTalep.IslemTutari = 0;
    this.odemeTalep.UserId=0;
    this.odemeTalep.EkstreBilgi = "";
    this.odemeTalep.MusteriAdi="";  
    this.odemeTalep.TaksitSayisi=1;
    this.odemeTalep.NetMi=false;
    this.odemeTalep.Degistiremesin=false;
  }
  getOdemeTalep():OdemeTalep{
    if(this.odemeTalep==undefined){
      this.setDefaultOdemeTalep();
    }
    return this.odemeTalep;
  }
}
