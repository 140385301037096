import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

  { path: 'dashboard', title: 'Ana Sayfa', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  {
    path: '', title: 'Ödeme İşlemleri', icon: 'fa fa-turkish-lira', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      //{path: '/odemeAl', title: 'Ödeme Al', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/odemeHazirla/0', title: 'Ödeme Al', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/odemeHazirla/1', title: 'Ödeme Hazırla', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Ödeme Raporları', icon: 'ft-printer', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/odeme-hareketleri', title: 'Ödeme Hareketleri', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/komisyonOran', title: 'Komisyon Oranları', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },
  {
    path: '', title: 'Banka Hareketleri', icon: 'icon-notebook', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/bankaHesapListesi', title: 'Banka Hesapları', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'İletişim', icon: 'ft-mail', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/smsListesi', title: 'SMS Listesi', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/smsGonder', title: 'SMS Gönder', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },
  {
    path: '', title: 'Ayarlar', icon: 'ft-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/epostaHesapBilgisi', title: 'Eposta Hesabı', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/smsHesapBilgisi', title: 'SMS Hesabı', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/kullaniciListesi', title: 'Kullanıcı Listesi', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/paramtrinkHesabi', title: 'Paramtrink Hesabı', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/kurumBilgisi', title: 'Kurum Bilgileri', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]

  }
];
