<nav class="navbar navbar-expand-lg navbar-light header-navbar mr-4 {{transparentBGClass}} mt-4" print.notPrintMe
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <div class="navbar-brand-center"> 
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="Apex logo" [src]="logoUrl"></div><span
                    class="text">APEX</span>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
       <div *ngIf="!isAuthenticated">
        <h3>{{jwtHelper.Kurum}}</h3>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item mr-2 d-none d-lg-block">
            <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
              (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
          </li>
          <!-- <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="drp-notification" href="javascript:;"
              data-toggle="dropdown" ngbDropdownToggle><i class="ft-bell font-medium-3"></i><span
                class="notification badge badge-pill badge-danger">4</span></a>
            <ul ngbDropdownMenu
              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">

              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <div class="d-flex">
                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i><span class="noti-title">4 Yeni Bildirim</span>
                  </div>
                </div>
              </li>
              <li class="scrollable-container" [perfectScrollbar]>
                <tbm-bildirimDropdown (yonlendir)="yonlendir($any($event))" (eventBind)="eventAlert($any($event))" [token]="token" [domainId]="domainId"
                [userId]="userId" [role]="role" [applicationType]="applicationType"></tbm-bildirimDropdown>
              </li>
              <li class="dropdown-menu-footer">
                <div class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1">
                  Read All Notifications
                </div>
              </li>
            </ul>
          </li> -->
            <tbm-bildirimDropdown (yonlendir)="yonlendir($any($event))" (eventBind)="eventAlert($any($event))" [token]="token" [domainId]="domainId"
              [userId]="userId" [role]="role" [applicationType]="applicationType"></tbm-bildirimDropdown>
          <li class="nav-item" ngbDropdown [placement]="placement">
            <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
              <i class="ft-user font-medium-3 blue-grey darken-4"></i>
              <p class="d-none">Kullanıcı Ayarları</p>
            </a>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="text-left">
              <a class="dropdown-item py-1" (click)="open(contentUserInfo)">
                <i class="ft-edit mr-2"></i>
                <span>Profil Bilgileri</span>
              </a>
              <a class="dropdown-item py-1" (click)="open(contentBildirimAbonelik)">
                <i class="ft-settings mr-2"></i>
                <span>Bildirim Ayarları</span>
              </a>
              <a class="dropdown-item py-1" (click)="open(contentPassChange)">
                <i class="ft-user mr-2"></i>
                <span>Şifre Değiştir</span>
              </a>
              <!--<a class="dropdown-item py-1" href="javascript:;" >
                                <i class="ft-settings mr-2"></i>
                                <span>Ayarlar</span>
                            </a>-->
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logOut();">
                <i class="ft-power mr-2"></i>
                <span>Güvenli Çıkış</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>





</nav><hr>
<!-- Navbar (Header) Ends -->
<ng-template #contentPassChange let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Şifre değişikliği</h4>
    <button type="button" class="close" aria-label="Kapat" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <app-sifreDegistir></app-sifreDegistir>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary btn-dark" (click)="c('')">Kapat</button>
  </div>
</ng-template>
<ng-template #contentUserInfo let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Profil Bilgileri</h4>
    <button type="button" class="close" aria-label="Kapat" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-kullaniciBilgileri></app-kullaniciBilgileri>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary btn-dark" (click)="c('')">Kapat</button>
  </div>
</ng-template>
<ng-template #contentBildirimAbonelik let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Bildirim Ayarları</h4>
    <button type="button" class="close" aria-label="Kapat" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <tbm-bildirimAbonelik (eventBind)="eventAlert($any($event))"></tbm-bildirimAbonelik>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary btn-dark" (click)="c('')">Kapat</button>
  </div>
</ng-template>
