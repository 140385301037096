import { Component, OnInit } from '@angular/core';
import { DomainInfoView } from 'app/model/DomainInfoView';
import { ModuleType } from 'app/model/ModuleType';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-komisyonOranlari',
  templateUrl: './komisyonOranlari.component.html',
  styleUrls: ['./komisyonOranlari.component.css']
})
export class KomisyonOranlariComponent implements OnInit {

  constructor(private authService: AuthService) { }

  domainInfo: DomainInfoView;
  moduleAdi: string = "paramtrink";
  moduleType: ModuleType = ModuleType.SanalPos;
  TOKEN_ROLES: string[] = [];

  ngOnInit() {
    this.domainInfoLoad();
    this.TOKEN_ROLES = this.authService.getCurrentUserRoles();
  }

  domainInfoLoad() {
    this.authService.GetDomainInfo().subscribe(data => {
      this.domainInfo = data;
    })
  }
  paramtrinkVarmi() {
    return this.domainInfo.modules.find(x => x.type == ModuleType.SanalPos);
  }

  isAuthorized() {
    return this.TOKEN_ROLES.includes("Owner") || this.TOKEN_ROLES.includes("Admin") || this.TOKEN_ROLES.includes("System") ||
      this.TOKEN_ROLES.includes("SanalPos_Admin") || this.TOKEN_ROLES.includes("SanalPos_Viewer") || this.TOKEN_ROLES.includes("SanalPos_Operator");
  }


}
