import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yapimAsamasinda',
  templateUrl: './yapimAsamasinda.component.html',
  styleUrls: ['./yapimAsamasinda.component.css']
})
export class YapimAsamasindaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
