import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';


import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { RouterModule, PreloadAllModules } from '@angular/router';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
//import { TabimWebsiteLibModule } from 'tabim-website-lib';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AppComponent } from './app.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { AlertifyService } from './services/alertify.service';
import { WelcomeComponent } from './welcome/welcome.component';
import { FileUploadModule } from 'ng2-file-upload';
import { NgbModalModule, NgbModalConfig, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WebSiteService } from './services/webSite.service';

import { ErrorsModule } from './errors';
import { ParametreService } from './services/parametre.service';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from 'environments/environment.prod';
import { TabimKurumLibModule } from 'tabim-kurum-lib';
import { TabimIletisimLibModule } from 'tabim-iletisim-lib';
import { TabimOrtakLibModule } from 'tabim-ortak-lib';
//import { ImageCropperModule } from 'ngx-image-cropper';

import { YapimAsamasindaComponent } from './errors/yapimAsamasinda/yapimAsamasinda.component';
import { SmsGonderComponent } from './iletisim/SmsGonder/SmsGonder.component';
import { SmsHesapBilgisiComponent } from './iletisim/SmsHesapBilgisi/SmsHesapBilgisi.component';
import { SmsListesiComponent } from './iletisim/SmsListesi/SmsListesi.component';
import { LoadingScreenComponent } from './araEkranlar/loadingScreen/loadingScreen.component';
import { BuyModuleComponent } from './araEkranlar/buyModule/buyModule.component';
import { OdemeAlComponent } from './paramtrink/OdemeAl/OdemeAl.component';
import { OdemeHazirlaComponent } from './paramtrink/OdemeHazirla/OdemeHazirla.component';
import { ParamtrinkHesabiComponent } from './paramtrink/ParamtrinkHesabi/ParamtrinkHesabi.component';
import { KomisyonOranlariComponent } from './paramtrink/komisyonOranlari/komisyonOranlari.component';
import { TabimSanalposLibModule } from 'tabim-sanalpos-lib'
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ParametreResolve } from './resolvers/parametre.resolve';
import { OdemeDetayComponent } from './paramtrink/OdemeDetay/OdemeDetay.component';
import { AuthInterceptor } from './Interceptor/AuthInterceptor';
import { BildirimlerComponent } from './iletisim/Bildirimler/Bildirimler.component';

import { NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';


import { MakbuzComponent } from './paramtrink/Makbuz/Makbuz.component';


import { TabimCoreLibModule } from 'tabim-core-lib';
import { UiSwitchModule } from 'ngx-ui-switch';

import { ForgotPasswordComponent } from './araEkranlar/forgotPassword/forgotPassword.component';
import { LoginComponent } from './araEkranlar/login/login.component';



import { TabimBankaonlineLibModule } from 'tabim-bankaonline-lib';
import { BankaHesapListesiComponent } from './bankaOnline/bankaHesapListesi/bankaHesapListesi.component';
import { BankaHesapDuzenleComponent } from './bankaOnline/bankaHesapDuzenle/bankaHesapDuzenle.component';
import { BankaHareketListesiComponent } from './bankaOnline/bankaHareketListesi/bankaHareketListesi.component';
import { BankaSorguListesiComponent } from './bankaOnline/bankaSorguListesi/bankaSorguListesi.component';
import { AccessDeniedComponent } from './araEkranlar/accessDenied/accessDenied.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NgModule } from '@angular/core';

//import { JwtModule } from '@auth0/angular-jwt';
import { appRoutes } from "./routes";
import { KurumComponent } from "./website/Kurum/Kurum.component";
import { OdemeHareketleriComponent } from "./paramtrink/OdemeHareketleri/OdemeHareketleri.component";
import { EpostaHesapBilgisiComponent } from "./website/Kurum/eposta-hesap-bilgisi/eposta-hesap-bilgisi.component";
import { KullanicilarComponent } from "./kullaniciIslemleri/KullaniciListesi/Kullanicilar.component";
import { KullaniciEkleComponent } from "./kullaniciIslemleri/KullaniciEkle/KullaniciEkle.component";
import { SharedModule } from "./shared/shared.module";
import { WINDOW_PROVIDERS } from "./shared/services/window.service";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function tokenGetter() {
  return localStorage.getItem("token");
}
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        WelcomeComponent,
        OdemeHazirlaComponent,
        OdemeAlComponent,
        KurumComponent,
        YapimAsamasindaComponent,
        OdemeHareketleriComponent,
        ParamtrinkHesabiComponent,
        SmsListesiComponent,
        SmsGonderComponent,
        SmsHesapBilgisiComponent,
        KomisyonOranlariComponent,
        LoadingScreenComponent,
        BuyModuleComponent,
        OdemeDetayComponent,
        MakbuzComponent,
        BildirimlerComponent,
        EpostaHesapBilgisiComponent,
        ForgotPasswordComponent,
        LoginComponent,
        BankaHesapListesiComponent,
        BankaHesapDuzenleComponent,
        BankaHareketListesiComponent,
        BankaSorguListesiComponent,
        AccessDeniedComponent,
        KullanicilarComponent,
        KullaniciEkleComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules }),
        FormsModule,
        SharedModule,
        NgxDatatableModule,
        FileUploadModule,
        NgbModalModule,
        NgxMaskModule.forRoot(),
        ErrorsModule,
        // TabimWebsiteLibModule.forRoot(environment.corePath),
        TabimKurumLibModule.forRoot(environment.corePath),
        TabimSanalposLibModule.forRoot(environment.paramtrinkPath, environment.corePath),
        TabimIletisimLibModule.forRoot(environment.corePath, environment.bildirimPath),
        TabimBankaonlineLibModule.forRoot(environment.corePath, environment.seffafPath),
        TabimOrtakLibModule.forRoot(environment.corePath),
       // ImageCropperModule,
        UiSwitchModule,
       // ChartistModule,
        /* JwtModule.forRoot({
           config: {
             tokenGetter: tokenGetter}
         }),*/
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        PerfectScrollbarModule
    ],
    providers: [
        AuthService,
        AuthGuard,
        AlertifyService,
        WebSiteService,
        ParametreService,
        NgbModalConfig,
        NgbModal,
        ParametreResolve,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
        WINDOW_PROVIDERS
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule { }
