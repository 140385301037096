<app-loadingScreen *ngIf="!domainInfo"></app-loadingScreen>
<div *ngIf="domainInfo">
  <div *ngIf="!paramtrinkVarmi()">
    <app-buyModule [moduleAdi]="moduleAdi"></app-buyModule>
  </div>

  <div *ngIf="paramtrinkVarmi()">
    <ng-template [ngIf]="!isAuthorized()">
      <app-accessDenied></app-accessDenied>
    </ng-template>

    <ng-template [ngIf]="isAuthorized()">
      <tbm-komisyonTablosu></tbm-komisyonTablosu>
    </ng-template>

  </div>
</div>
