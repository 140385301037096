import { Component, OnInit } from "@angular/core";


import { ModuleType } from "app/model/ModuleType";
import { DomainInfoView } from "app/model/DomainInfoView";

import { Router } from "@angular/router";
import { AuthService } from "app/services/auth.service";
import { AlertifyService } from "app/services/alertify.service";
import { Tasarim } from "app/model/Tasarim";
import { ePostaHesap } from "app/model/ePostaHesap";

@Component({
  selector: 'app-eposta-hesap-bilgisi',
  templateUrl: './eposta-hesap-bilgisi.component.html',
  styleUrls: ['./eposta-hesap-bilgisi.component.css']
})
export class EpostaHesapBilgisiComponent implements OnInit {
  domainInfo: DomainInfoView;
  metin: string = "WebSayfası modülünü kullanma hakkınız yoktur.Bilgi için Tabim Bilişim'i arayınız.";
  etiket: string = "webSayfasi";
  TOKEN_ROLES: string[] = [];

  moduleTypeWebSayfasi: ModuleType = ModuleType.WebSitesi;
  moduleTypeSanalPos: ModuleType = ModuleType.SanalPos;
  copiedEmailHesap: ePostaHesap = new ePostaHesap();
  constructor(
    private alertifyService: AlertifyService,
    private authService: AuthService,
    private router: Router
  ) { }
  tasarim: Tasarim = new Tasarim();
  ngOnInit() {
    this.domainInfoLoad();
  }

  domainInfoLoad() {
    this.authService.GetDomainInfo().subscribe(data => {
      this.domainInfo = data;
    });

    this.TOKEN_ROLES = this.authService.getCurrentUserRoles();
  }
  webSayfasiVarmi() {
    return this.domainInfo.modules.find(x => x.type == ModuleType.WebSitesi);
  }

  sanalPosVarmi() {
    return this.domainInfo.modules.find(x => x.type == ModuleType.SanalPos);
  }
  eventAlert(data) {
    this.router.navigateByUrl('/epostaHesapBilgisi', { skipLocationChange: true });
    if (data.status == true) {
      if (data.eventType == 1) {
        this.alertifyService.success("İşleminiz Kayıt Edilmiştir");
      } else if (data.eventType == 3) {
        this.alertifyService.success("İşleminiz Güncellenmiştir");
      } else if (data.eventType == 2) {
        this.alertifyService.success("İşleminiz Silinmiştir");
      } else if (data.eventType == 4) {
        this.alertifyService.success("İşleminiz yapılmıştır");
      }
    } else {
      if (data.eventType == 1) {
        this.alertifyService.error("İşleminiz Kayıt Edilememiştir");
      } else if (data.eventType == 3) {
        this.alertifyService.error("İşleminiz Güncellenmemştir");
      } else if (data.eventType == 2) {
        this.alertifyService.error("İşleminiz Silenememiştir");
      } else if (data.eventType == 4) {
        this.alertifyService.error(data.message);
      }
      else if (data.eventType == 5) {
        this.alertifyService.error("Zorunlu Alanları Eksiksiz Doldurun");
      }
    }
  }

  eventCopyEmailHesap(emailHesap: ePostaHesap) {
    this.copiedEmailHesap = new ePostaHesap();
    this.copiedEmailHesap.EnableSsl = emailHesap.EnableSsl;
    this.copiedEmailHesap.Host = emailHesap.Host;
    this.copiedEmailHesap.MailAddress = emailHesap.MailAddress;
    this.copiedEmailHesap.Password = emailHesap.Password;
    this.copiedEmailHesap.Port = emailHesap.Port;
    this.copiedEmailHesap.UserName = emailHesap.UserName;
  }

  isAuthorized() {
    return this.TOKEN_ROLES.includes("Owner") || this.TOKEN_ROLES.includes("Admin") || this.TOKEN_ROLES.includes("System");
  }


}
