import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DomainInfoView } from 'app/model/DomainInfoView';
import { AuthService } from 'app/services/auth.service';
import { ModuleType } from 'app/model/ModuleType';
@Component({
  selector: 'app-SmsListesi',
  templateUrl: './SmsListesi.component.html',
  styleUrls: ['./SmsListesi.component.css']
})
export class SmsListesiComponent implements OnInit {
  id: number;
  constructor(private modalService: NgbModal, private authService: AuthService) { }
  private modalRef: NgbModalRef;
  @ViewChild('content') contentSms: ModalDirective;
  domainInfo: DomainInfoView;
  moduleAdi: string = "SMS";
  TOKEN_ROLES: string[] = [];



  ngOnInit() {
    this.authService.GetDomainInfo().subscribe(data => {
      this.domainInfo = data;
    });
    this.TOKEN_ROLES = this.authService.getCurrentUserRoles();
  }
  smsVarmi() {
    debugger;
    return this.domainInfo.modules.find(x => x.type == ModuleType.Iletisim);
  }
  smsDetail(sms) {
    this.id = sms.id;
    this.modalRef = this.modalService.open(this.contentSms, { size: "lg" });
  }

  isAuthorized() {
    return this.TOKEN_ROLES.includes("Owner") || this.TOKEN_ROLES.includes("Admin") || this.TOKEN_ROLES.includes("System") || this.TOKEN_ROLES.includes("Iletisim_Admin") || this.TOKEN_ROLES.includes("Iletisim_Viewer");
  }

}
