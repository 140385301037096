import { Injectable } from "@angular/core";
import { LoginUser } from "../model/LoginUser";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { AlertifyService } from "./alertify.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { UserInfoView } from "../model/UserInfoView";
import { NewPasswordView } from "../model/NewPaswordView";
import { DomainInfoView } from "../model/DomainInfoView";
import { ParametreService } from "./parametre.service";
import { ForgotPasswordView } from "../model/ForgotPasswordView";
import { BildirimService } from "./bildirim.service.";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private alertify: AlertifyService,
    private bildirimService: BildirimService
  ) { }
  path = environment.corePath + "api/Auth/";
  TOKEN_KEY = "token";
  userToken: any;
  decodedToken: any;
  jwtHelper: JwtHelperService = new JwtHelperService();

  login(loginUser: LoginUser) {
    this.isRememberMe = loginUser.rememberMe;
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    this.httpClient
      .post(environment.corePath + "api/Auth/loginUser", loginUser, { headers })
      .subscribe(data => {
        this.saveToken(data);
        this.userToken = data;
        this.decodedToken = this.jwtHelper.decodeToken(data.toString());
        this.alertify.success("Sisteme Giriş Yapıldı");
        this.router.navigateByUrl(loginUser.redirectUrl);
      },
        error => {
          this.alertify.error(error.error);
        });
  }

  forgotPasswordForApplication(forgotPasswordView: ForgotPasswordView): Observable<ForgotPasswordView> {
    return this.postHttp("forgotPasswordForApplication", forgotPasswordView);
  }

  saveToken(token) {
    if (this.isRememberMe) {
      localStorage.setItem(this.TOKEN_KEY, token);
    }
    else {
      sessionStorage.setItem(this.TOKEN_KEY, token);
    }
  }

  isRememberMe: boolean;
  checkRememberMe(): boolean {
    let sessionToken = sessionStorage.getItem(this.TOKEN_KEY);
    let localToken = localStorage.getItem(this.TOKEN_KEY);
    if (sessionToken) {
      return false;
    }
    else if (localToken) {
      return true;
    }
  }

  logOut() {
    this.bildirimService.loginControl().subscribe(() => {
      this.removeItem();
    }, () => {
      this.removeItem();
    });
  }



  removeItem() {
    localStorage.removeItem(this.TOKEN_KEY);
    sessionStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem("domainInfo");
  }

  loggedIn() {
    return this.jwtHelper.isTokenExpired( this.token);
  }

  get token() {
    let storageType: boolean = this.checkRememberMe();
    if (storageType == true) {
      return localStorage.getItem("token");
    }
    else {
      return sessionStorage.getItem("token");
    }
  }

  getCurrentUserId() {
    return this.jwtHelper.decodeToken(this.token).nameid;
  }
  getCurrentUserDomain() {
    return this.jwtHelper.decodeToken(this.token).Domain;
  }
  getCurrentUser() {
    return this.jwtHelper.decodeToken(this.token);
  }
  getUserInfo(): Observable<UserInfoView> {
    return this.getHttp("GetUserInfo");
  }
  getCurrentUserRoles() {
    return this.jwtHelper.decodeToken(this.token).role;
  }

  changePassword(newPassword: NewPasswordView) {
    return this.postHttp("changePassword", newPassword);
  }

  GetDomainInfo(): Observable<DomainInfoView> {
    if (!localStorage.getItem("domainInfo")) {
      let srv = this.getHttp<DomainInfoView>("GetDomainInfo");
      srv.subscribe(data => {
        localStorage.setItem("domainInfo", JSON.stringify(data));
      });
      return srv;
    } else {
      return new Observable<DomainInfoView>(data => {
        data.next(JSON.parse(localStorage.getItem("domainInfo")));
        data.complete();
      });
    }
  }

  private postHttp<T>(functionName: string, param: any): Observable<T> {
    let params = new HttpParams();

    const options = {
      headers: new HttpHeaders().set(

        "Authorization",
        "Bearer " + this.token
      ),
      params: params,
      reportProgress: true,
      withCredentials: false
    };

    return this.httpClient.post<T>(
      environment.corePath + "api/Auth/" + functionName,
      param,
      options
    );
  }

  private getHttp<T>(functionName: string): Observable<T> {
    let params = new HttpParams();

    const options = {
      headers: new HttpHeaders().set(
        "Authorization",
        "Bearer " + this.token
      ),
      params: params,
      reportProgress: true,
      withCredentials: false
    };
    return this.httpClient.get<T>(this.path + functionName, options);
  }

}
