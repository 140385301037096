<tbm-bildirimler (yonlendir)="yonlendir($any($event))"></tbm-bildirimler>


<!-- <section id="paging" class="mb-3">
  <div class="row text-left">
    <div class="col-4">
      <div class="content-header">Bildirim Listesi</div>
    </div>
    <div class="col-4 position-relative has-icon-right">
      <input type="text" [(ngModel)]="aranan" class="form-control round" placeholder="Bildirim Mesajı Ara"
        (keyup)='updateFilter()' />
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ngx-datatable class="bootstrap" [rows]="bildirimler" [messages]="my_messages"
        [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="10" (page)="onChange($any($event))">

        <ngx-datatable-column name="Mesaj" sortable="true" prop="bildirim.mesaj" title="Mesaj">
        </ngx-datatable-column>

        <ngx-datatable-column name="Okundu Mu?" sortable="true" prop="okunanBildirim" title="Okundu Mu?">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <p *ngIf="row.okunanBildirim == null">Okunmadı</p>
            <p *ngIf="row.okunanBildirim != null">Okundu</p>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Okunma Tarihi " sortable="true" prop="okunanBildirim.operationDate" title="Okunma Tarihi ">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <p *ngIf="row.okunanBildirim != null">
              {{row.okunanBildirim.operationDate | date:'dd/MM/yyyy hh:mm'}}
            </p>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Kayıt Tarihi " sortable="true" prop="bildirim.operationDate" title="Kayıt Tarihi ">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.bildirim.operationDate | date:'dd/MM/yyyy hh:mm'}}
          </ng-template>
        </ngx-datatable-column>


        <ngx-datatable-column name="İşlem" sortable="false" prop="id" title="İşlem">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a *ngIf="row.bildirim.redirectUrl" class="btn btn-sm btn-raised btn-outline-success" (click)="redirect(row)" >
              Detay
            </a>
            &nbsp;
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</section> -->
