export class Banka {
  id: number;
  sitE_ID: number;
  sube: string;
  subeno: string;
  hesaP_NO: string;
  iban: string;
  ftP_KULLANICI: string;
  ftP_SIFRE: string;
  enT_ONLINE: string;
}
