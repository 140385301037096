import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-buyModule',
  templateUrl: './buyModule.component.html',
  styleUrls: ['./buyModule.component.css']
})
export class BuyModuleComponent implements OnInit {
  @Input() moduleAdi:string;
  constructor() { }

  ngOnInit() {
  }

}
