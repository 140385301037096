<app-loadingScreen *ngIf="!domainInfo"></app-loadingScreen>
<div *ngIf="domainInfo">
  <div *ngIf="!smsVarmi()">
    <app-buyModule [moduleAdi]="moduleAdi"></app-buyModule>
  </div>
  <div *ngIf="smsVarmi()">
    <ng-template [ngIf]="!isAuthorized()">
      <app-accessDenied></app-accessDenied>
    </ng-template>

    <ng-template [ngIf]="isAuthorized()">
      <tbm-smsListe (smsDetail)="smsDetail($any($event))"></tbm-smsListe>
    </ng-template>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Sms Detayları</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <tbm-smsDetay [id]="id"></tbm-smsDetay>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Kapat</button>
  </div>
</ng-template>
