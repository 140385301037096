import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomainInfoView } from 'app/model/DomainInfoView';
import { ModuleType } from 'app/model/ModuleType';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-OdemeHareketleri',
  templateUrl: './OdemeHareketleri.component.html',
  styleUrls: ['./OdemeHareketleri.component.css']
})
export class OdemeHareketleriComponent implements OnInit {

  constructor(private router: Router,
    private authService: AuthService) { }

  moduleAdi: string = "paramtrink";
  domainInfo: DomainInfoView;
  TOKEN_ROLES: string[] = [];

  ngOnInit() {
    this.domainInfoLoad();
    this.TOKEN_ROLES = this.authService.getCurrentUserRoles();
  }

  odemeDetay(id: number) {
    this.router.navigateByUrl("/odemeDetay/" + id);
  }

  odemeMakbuz(id: number) {
    this.router.navigateByUrl("/makbuz/" + id);
  }

  paramtrinkVarmi() {
    return this.domainInfo.modules.find(x => x.type == ModuleType.SanalPos);
  }

  domainInfoLoad() {
    this.authService.GetDomainInfo().subscribe(data => {
      this.domainInfo = data;
    })
  }

  isAuthorized() {
    return this.TOKEN_ROLES.includes("Owner") || this.TOKEN_ROLES.includes("Admin") || this.TOKEN_ROLES.includes("System") ||
      this.TOKEN_ROLES.includes("SanalPos_Admin") || this.TOKEN_ROLES.includes("SanalPos_Viewer");
  }

}
