import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertModel } from 'app/model/AlertModel';
import { AlertifyService } from 'app/services/alertify.service';

@Component({
  selector: 'app-bankaHareketListesi',
  templateUrl: './bankaHareketListesi.component.html',
  styleUrls: ['./bankaHareketListesi.component.css']
})
export class BankaHareketListesiComponent implements OnInit {

  constructor(private alertifyService: AlertifyService,
    private activatedRoute: ActivatedRoute) { }

  bankaOnlineHesapId: number = 0;

  ngOnInit() {
    this.getActivatedRoute();
  }

  getActivatedRoute() {
    this.activatedRoute.params.subscribe(param => {
      this.bankaOnlineHesapId = param["bankaOnlineHesapId"];
    });
  }

  eventAlert(alert: AlertModel) {
    if (alert.status == true) {
      if (alert.eventType == 1) {
        this.alertifyService.success("İşleminiz Kayıt Edilmiştir");
      } else if (alert.eventType == 3) {
        this.alertifyService.success("İşleminiz Güncellenmiştir");
      } else if (alert.eventType == 2) {
        this.alertifyService.success("İşleminiz Silinmiştir");
      } else if (alert.eventType == 4) {
        this.alertifyService.success(alert.message);
      }
    } else {
      if (alert.eventType == 1) {
        this.alertifyService.error(alert.message);
      } else if (alert.eventType == 3) {
        this.alertifyService.error(alert.message);
      } else if (alert.eventType == 2) {
        this.alertifyService.error(alert.message);
      } else if (alert.eventType == 4) {
        this.alertifyService.error(alert.message);
      }
      else if (alert.eventType == 5) {
        this.alertifyService.error("Zorunlu Alanları Eksiksiz Doldurun");
      }
    }
  }

}
