import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { LoginUser } from '../model/LoginUser';
import { WelcomePageType } from '../model/WelcomePageType.enum';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  constructor(private authService: AuthService) { }

  WelcomePageType = WelcomePageType;
  selectedWelcomePageType: WelcomePageType = WelcomePageType.login;

  ngOnInit() {
  }


  get isAuthenticated() {
    return this.authService.loggedIn();
  }

  eventLogin() {
    this.selectedWelcomePageType = WelcomePageType.login;
  }

  eventRegister() {
    // this.selectedWelcomePageType = WelcomePageType.register;
  }

  eventForgotPassword() {
    this.selectedWelcomePageType = WelcomePageType.forgotPassword;
  }

}
