import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import { AlertifyService } from 'app/services/alertify.service';
import { OdemeTalep } from 'app/model/OdemeTalep';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ParametreService } from 'app/services/parametre.service';
import { environment } from 'environments/environment.prod';
import { ActivatedRoute } from '@angular/router';
import { PassOdemeTalepService } from 'app/services/passOdemeTalep.service';
import { Subscription } from 'rxjs/internal/Subscription';


@Component({
  selector: 'app-OdemeAl',
  templateUrl: './OdemeAl.component.html',
  styleUrls: ['./OdemeAl.component.css']
})
export class OdemeAlComponent implements OnInit {

  odemeTalep: OdemeTalep = new OdemeTalep();
  odemeTalepForm: FormGroup;
  odemePath =  environment.paramtrinkPath + "Odeme/OdemeHazirlaApp";
  resolverSub: Subscription;


  constructor( private formBuilder: FormBuilder,private activatedRoute: ActivatedRoute,
    private passOdemeTalep: PassOdemeTalepService) { }

  @ViewChild('iframe') iframe: ElementRef;
  @ViewChild('send_hptoken') send_hptoken: ElementRef;
  @ViewChild('ContentRef') iframe_holder: ElementRef;



  ngAfterViewInit(){
    this.send_hptoken.nativeElement.submit();
  }

  ngOnInit() {
    this.resolverSub = this.activatedRoute.data.subscribe((data) => {
      this.odemeTalep = this.passOdemeTalep.getOdemeTalep();
      this.odemeTalep.appId = data.account.appId;
      this.odemeTalep.appKey = data.account.appKey;
      this.passOdemeTalep.setDefaultOdemeTalep();
    });

  }

  ngOnDestroy() {
    if (this.resolverSub) {
      this.resolverSub.unsubscribe();
    }
  }
}
