import { Component, OnInit } from '@angular/core';

import { AlertifyService } from 'app/services/alertify.service';
import { AuthService } from 'app/services/auth.service';
import { NewPasswordView } from 'app/model/NewPaswordView';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sifreDegistir',
  templateUrl: './sifreDegistir.component.html',
  styleUrls: ['./sifreDegistir.component.css']
})
export class SifreDegistirComponent implements OnInit {
  passwordForm:FormGroup;
  
  constructor( private formBuilder: FormBuilder, private alertifyService: AlertifyService,private authService:AuthService) { }
  newPassword:NewPasswordView=new NewPasswordView();

  ngOnInit() {
    
    this.createPasswordForm();

  }


createPasswordForm() {
  this.passwordForm = this.formBuilder.group({
   oldPass:["" ,Validators.required],
   password: ["", [Validators.required,Validators.minLength(4),Validators.maxLength(20)] ],
   confirmPassword:["",Validators.required]
  },{validator:this.passwordMatchValidator}
  );
}
passwordMatchValidator(g:FormGroup){
  return g.get('password').value === 
  g.get('confirmPassword').value?null:{mismatch:true}
}
sifreUpdate(){
  if (this.passwordForm.valid) {
    this.newPassword = Object.assign({},this.passwordForm.value) ;
    this.authService.changePassword(this.newPassword).subscribe(
      data => {        
        this.alertifyService.success("Şifreniz Başarıyla Güncellendi.")   
       },
      error => {
        this.alertifyService.error(error.error);
      }
      );
  }
}
}
