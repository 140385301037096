<div *ngIf="!isAuthenticated" (resized)="onResize($event)">
  <app-navbar (seachTextEmpty)="checkNavbarSeachTextEmpty($event)"></app-navbar>

  <div class="wrapper" [ngClass]="{'show-overlay': !isNavbarSeachTextEmpty}" (click)="onWrapperClick();" (window:resize)="onResize($event)">

    <!-- *SIDE* Menu starts -->
    <div appSidebar data-image="assets/img/sidebar-bg/06.jpg" *ngIf="menuPosition === 'Side' || displayOverlayMenu"
      [ngClass]="{'main-menu': menuPosition === 'Side' || displayOverlayMenu, 'menu-fixed': menuPosition === 'Side' || displayOverlayMenu, 'menu-native-scroll': false }" class="app-sidebar notPrintMe"
      (mouseenter)="sidebarMouseenter($event)" (mouseleave)="sidebarMouseleave($event)" data-active-color="white"
      [attr.data-background-color]="config?.layout.variant === 'Transparent' ? 'black': bgColor"
      [attr.data-image]="bgImage">
      <app-sidebar print.notPrintMe class="notPrintMe"></app-sidebar>
      <div class="sidebar-background" [ngStyle]="{'background-image': 'url(' + bgImage + ')'}"
        *ngIf="config?.layout.sidebar.backgroundImage"></div>
    </div>
    <!-- *SIDE* Menu ends -->

    <div class="main-panel">
      <div class="main-content">
        <div class="content-overlay"></div>
        <div class="content-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
      <!-- FOOTER -->
      <app-footer></app-footer>
      <!-- Scroll to top button -->
      <button class="btn btn-primary scroll-top" type="button" *ngIf="isScrollTopVisible" (click)="scrollToTop()"><i class="ft-arrow-up"></i></button>
    </div>

    <!-- *CUSTOMIZER* starts -->
    <!-- <app-customizer></app-customizer> -->
    <!-- *CUSTOMIZER* ends -->



  </div>
  </div>
  <div *ngIf="isAuthenticated">
    <app-welcome></app-welcome>
  </div>
