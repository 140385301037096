import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { BankaOnlineStatisticsChartView } from '../model/BankaOnlineStatisticsChartView';


@Injectable({
  providedIn: 'root'
})
export class BankaOnlineService {

  apiPath = environment.corePath + "api/BankaOnline/";
  constructor(private httpClient: HttpClient) {
  }

  private getHttp<T>(functionName: string): Observable<T> {
    return this.httpClient.get<T>(this.apiPath + functionName);
  }

  private postHttp<T>(functionName: string, param: any): Observable<T> {
    return this.httpClient.post<T>(this.apiPath + functionName, param);
  }

  getBankaOnlineStatisticsForChart(): Observable<BankaOnlineStatisticsChartView> {
    return this.getHttp("getBankaOnlineStatisticsForChart");
  }




}
