<app-loadingScreen *ngIf="!domainInfo"></app-loadingScreen>
<div *ngIf="domainInfo">
  <div *ngIf="!paramtrinkVarmi()">
    <app-buyModule [moduleAdi]="moduleAdi"></app-buyModule>
  </div>

  <div *ngIf="paramtrinkVarmi()">
    <ng-template [ngIf]="!isAuthorized()">
      <app-accessDenied></app-accessDenied>
    </ng-template>

    <ng-template [ngIf]="isAuthorized()">
      <tbm-odeme-hesap (eventBind)="metod($any($event))" (odemeTurIslem)="odemeTurIslem($any($event))" [loadPage]="loadPage">
      </tbm-odeme-hesap>
    </ng-template>

  </div>
</div>




<ng-template #contentTur let-modal id="myModalTur">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Ödeme Tür İşlemleri</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <tbm-odemeTur-duzenle [odemeTurEdit]="odemeTurEdit" (odemeTurIslemResult)="odemeTurIslemResult($any($event))">
    </tbm-odemeTur-duzenle>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">
      Kapat
    </button>
  </div>
</ng-template>
