<tbm-bankaHesapDuzenle [uygulama]="uygulama" [bankaHesapId]="bankaOnlineHesapId" [seffafV2Banka]="seffafV2Banka" [domainInfo]="domainInfo" (alert)="eventAlert($any($event))"
  (returnBankaHesapList)="eventReturnBankaHesapList()" (openSeffafV2BankaListesi)="eventOpenSeffafV2BankaListesi()">
</tbm-bankaHesapDuzenle>

<ng-template #contentSeffafV2BankaListesi let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Banka İşlemleri</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <tbm-seffafV2BankaListesi (selectedBanka)="eventCloseSeffafV2BankaListesi($any($event))" (alert)="eventAlert($any($event))">
    </tbm-seffafV2BankaListesi>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Kapat</button>
  </div>
</ng-template>
