
<form [formGroup]='passwordForm' class="form form-horizontal striped-rows form-bordered" (ngSubmit)="sifreUpdate()">
  <div class="form-body">
      <div class="form-group row">
          <label class="col-md-3 label-control" for="oldPass">Eski Şifreniz:</label>
          <div class="col-md-9">
                 <input type="password" id="oldPass" formControlName="oldPass" class="form-control" >
                 <span class="help-block" *ngIf="passwordForm.get('oldPass').hasError('required') && passwordForm.get('oldPass').touched">Eski Şifrenizi Girmeniz Gerekiyor!</span>
          </div>
          <label class="col-md-3 label-control" for="password">Yeni Şifreniz:</label>
            <div class="col-md-9">
                   <input type="password" id="password" formControlName="password" class="form-control"  >
                      <span class="help-block" *ngIf="passwordForm.get('password').hasError('required') && passwordForm.get('password').touched">Yeni Şifrenizi Girmeniz Gerekiyor!</span>
                      <span class="help-block" *ngIf="passwordForm.get('password').hasError('minlength') && passwordForm.get('password').touched">Yeni Şifreniz En Az 4 Haneli Olmalı!</span>
                      <span class="help-block" *ngIf="passwordForm.get('password').hasError('maxlength') && passwordForm.get('password').touched">Yeni Şifreniz En Fazla 20 Haneli Olabilir</span>
                    </div>
            <label class="col-md-3 label-control" for="confirmPassword">Yeni Şifrenizi Tekrar Girin:</label>
            <div class="col-md-9">
                   <input type="password" id="confirmPassword" formControlName="confirmPassword" class="form-control" >
                   <span class="help-block" *ngIf="passwordForm.get('confirmPassword').hasError('required') && passwordForm.get('confirmPassword').touched">Yeni Şifrenizi Tekrar Girmeniz Gerekiyor!</span>
                    <span class="help-block" *ngIf="passwordForm.get('confirmPassword').touched   && passwordForm.hasError('mismatch')">Yeni Girdiğiniz Şifreler Uyuşmuyor! </span>

                    </div>
      </div>
  </div>
  <button type="submit" class="btn btn-raised btn-success" [disabled]="!passwordForm.valid"><i class="ft-check"></i>Kaydet</button>

  </form>


