import { Component, OnInit } from '@angular/core';
import { AlertifyService } from 'app/services/alertify.service';
import { AlertModel } from 'app/model/AlertModel';
import { Router } from '@angular/router';

@Component({
  selector: 'app-KullaniciEkle',
  templateUrl: './KullaniciEkle.component.html',
  styleUrls: ['./KullaniciEkle.component.css']
})
export class KullaniciEkleComponent implements OnInit {

  constructor(
    private alertifyService: AlertifyService,
    private router: Router
  ) { }

  ngOnInit() {

  }

  eventAlert(data: AlertModel) {
    if (data.status == true) {
      if (data.eventType == 1) {
        this.alertifyService.success("İşleminiz Kayıt Edilmiştir");
        this.router.navigateByUrl("/kullaniciListesi");
      } else if (data.eventType == 3) {
        this.alertifyService.success("İşleminiz Güncellenmiştir");
      } else if (data.eventType == 2) {
        this.alertifyService.success("İşleminiz Silinmiştir");
      } else if (data.eventType == 4) {
        this.alertifyService.success("İşleminiz yapılmıştır");
      }
    } else {
      if (data.eventType == 1) {
        this.alertifyService.error("İşleminiz Kayıt Edilememiştir");
      } else if (data.eventType == 3) {
        this.alertifyService.error("İşleminiz Güncellenmemştir");
      } else if (data.eventType == 2) {
        this.alertifyService.error("İşleminiz Silenememiştir");
      } else if (data.eventType == 4) {
        this.alertifyService.error("İşleminiz yapılamamıştır");
      }
    }
  }

}
