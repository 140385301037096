import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertifyService } from 'app/services/alertify.service';
import { AuthService } from 'app/services/auth.service';
@Component({
  selector: "app-Kurum",
  templateUrl: "./Kurum.component.html",
  styleUrls: ["./Kurum.component.css"],
  providers: [],

})
export class KurumComponent implements OnInit {
  private modalRef: NgbModalRef;
  @ViewChild('contentAdres') contentAdres: ModalDirective;
  @ViewChild('contentIletisim') contentIletisim: ModalDirective;
  @ViewChild('contentSube') contentSube: ModalDirective;
  @ViewChild('content') content: ModalDirective;
  @ViewChild('contentResim') contentResim: ModalDirective;


  TOKEN_ROLES: string[] = [];
  adresId: number;
  domainName: string;
  kendiKurumum: boolean;
  loadSayfa: boolean = false;
  subeId: number;
  iletisimId: number;
  kurumId: number;
  domainId: number;
  constructor(private modalService: NgbModal, private alertifyService: AlertifyService, private authService: AuthService) { }
  ngOnInit() {
    this.TOKEN_ROLES = this.authService.getCurrentUserRoles();
  }

  isAuthorized() {
    return this.TOKEN_ROLES.includes("Owner") || this.TOKEN_ROLES.includes("Admin") || this.TOKEN_ROLES.includes("System") || this.TOKEN_ROLES.includes("User");
  }

  adresDuzenle(adresim: { adresId: number, subeId: number, domainId: number }) {
    this.loadSayfa = false;
    this.adresId = adresim.adresId;
    this.subeId = adresim.subeId;
    this.domainId = adresim.domainId;
    this.modalRef = this.modalService.open(this.contentAdres, { size: "lg" });
  }
  iletisimDuzenle(iletisimim: { iletisimId: number, subeId: number, domainId: number }) {
    this.loadSayfa = false;
    this.iletisimId = iletisimim.iletisimId;
    this.subeId = iletisimim.subeId;
    this.domainId = iletisimim.domainId;
    this.modalRef = this.modalService.open(this.contentIletisim, { size: "lg" });
  }
  subeDuzenle(subem: { subeId: number, kurumId: number, domainId: number }) {
    this.loadSayfa = false;
    this.subeId = subem.subeId;
    this.kurumId = subem.kurumId;
    this.domainId = subem.domainId;
    this.modalRef = this.modalService.open(this.contentSube, { size: "lg" });
  }
  kurumDuzenle(kurumum: { kurumId: number, domainName: string, kendikurumum: boolean }) {
    this.loadSayfa = false;
    this.kurumId = kurumum.kurumId;
    this.domainName = kurumum.domainName;
    this.kendiKurumum = kurumum.kendikurumum;
    this.modalRef = this.modalService.open(this.content, { size: "lg" });
  }
  resimDuzenle(resimSubem: { subeId: number, kurumId: number, domainId: number }) {
    this.loadSayfa = false;
    this.subeId = resimSubem.subeId;
    this.domainId = resimSubem.domainId;
    this.kurumId = resimSubem.kurumId;
    this.modalRef = this.modalService.open(this.contentResim, { size: "lg" });
  }
  eventAlert(data) {
    this.loadSayfa = true;
    this.modalRef.close();
    if (data.status == true) {
      if (data.eventType == 1) {
        this.alertifyService.success("İşleminiz Kayıt Edilmiştir");
      } else if (data.eventType == 3) {
        this.alertifyService.success("İşleminiz Güncellenmiştir");
      } else if (data.eventType == 2) {
        this.alertifyService.success("İşleminiz Silinmiştir");
      } else if (data.eventType == 4) {
        this.alertifyService.success("İşleminiz yapılmıştır");
      }
    } else {
      if (data.eventType == 1) {
        this.alertifyService.error("İşleminiz Kayıt Edilememiştir");
      } else if (data.eventType == 3) {
        this.alertifyService.error("İşleminiz Güncellenmemştir");
      } else if (data.eventType == 2) {
        this.alertifyService.error("İşleminiz Silenememiştir");
      } else if (data.eventType == 4) {
        this.alertifyService.error(data.message);
      }
      else if (data.eventType == 5) {
        this.alertifyService.error("Zorunlu Alanları Eksiksiz Doldurun");
      }
    }
  }

}
