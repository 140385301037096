import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-Bildirimler',
  templateUrl: './Bildirimler.component.html',
  styleUrls: ['./Bildirimler.component.css']
})
export class BildirimlerComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }

  yonlendir(event){
    this.router.navigateByUrl(event);
  }
  



//   my_messages = {
//     'emptyMessage': 'Kayıt yok!',
//     'totalMessage': 'Toplam'
//   };

//   bildirimler: BildirimOkuView[];
//   bildirimlerTmp:BildirimOkuView[];
//   bildirimOkunanlar:BildirimOkuView[];
//   aranan:string="";

//   constructor(private ortakService:OrtakService,private signalR: SignalRService,private router: Router) { }

//   ngOnInit() {
//     this.getBildirimler();
//   }

//   getBildirimler() {
//     this.ortakService.getBildirimler().subscribe(data => {
//       this.bildirimler = data;
//       this.bildirimlerTmp=data;
//       this.bildirimOkunanlar=this.bildirimler.slice(0,10);
//       this.updateFilter();
//     });
//   }

//    updateFilter() {
//      if(this.bildirimlerTmp != undefined){
//       const val = this.aranan.toLowerCase();
//       const temp = this.bildirimlerTmp.filter(function (d) {
//         return d.bildirim.mesaj.toLowerCase().indexOf(val) !== -1 || !val ;
//       });
//       this.bildirimler = temp;

//       let sayfa:number=0;
//       sayfa=this.sonSayfa;
//       while (this.bildirimler.length<=sayfa) {
//         sayfa=sayfa-10;
//       }
//       this.bildirimOkunanlar=this.bildirimler.slice(sayfa,sayfa+10);
//     }
//     this.bildirimOkundu();
//   }

//   sonSayfa:number=0;
//   onChange(event: any): void {
//     this.sonSayfa=event.offset;
//     this.sonSayfa=this.sonSayfa*10;
//     this.bildirimOkunanlar=this.bildirimler.slice(this.sonSayfa,this.sonSayfa+10);
//     this.bildirimOkundu();
//  }

//  bildirimOkundu(){
//   let sendBildirimlerGoster:Bildirim[]=[];
//   this.bildirimOkunanlar.forEach(goster => {
//     if(goster.okunanBildirim == null && goster.bildirim.tip != BildirimTip.Zorunlu){
//         sendBildirimlerGoster.push(goster.bildirim);
//     }
//   });
//   if(sendBildirimlerGoster.length>0){
//     this.ortakService.bildirimOkundu(sendBildirimlerGoster).subscribe(data=>{
//       if(data==true) {
//         this.getBildirimler();
//         this.signalR.ClientBildiriMGonder("Bildirim Okundu");
//       }
//     });
//   }
//  }

//  redirect(row:BildirimOkuView){
//   if(row.bildirim.redirectUrl){

//     if(row.okunanBildirim==null){//okunmamışsa
//       let sendBildirimGoster:Bildirim[]=[];
//       sendBildirimGoster.push(row.bildirim);
//       if(sendBildirimGoster.length>0){
//         this.ortakService.bildirimOkundu(sendBildirimGoster).subscribe(data=>{
//           if(data==true) {
//             this.signalR.ClientBildiriMGonder("Bildirim Okundu");
//             this.router.navigateByUrl(row.bildirim.redirectUrl);
//           }
//         });
//       }
//     }
//   }
// }


}
