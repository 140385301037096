import { Component, OnInit } from '@angular/core';

//import * as Chartist from 'chartist';
import { ParametreService } from '../services/parametre.service';
import { OdemeVeriView } from '../model/OdemeVeriView';
import { trigger, state, animate, style, transition } from '@angular/animations';
import { BankaOnlineService } from '../services/bankaOnline.service';
import { BankaOnlineStatisticsChartView } from '../model/BankaOnlineStatisticsChartView';
import { DomainInfoView } from '../model/DomainInfoView';
import { AuthService } from '../services/auth.service';
import { ModuleType } from '../model/ModuleType';
declare var require: any;
const data: any = require('../shared/data/chartist.json');
export interface Chart {
  type: any;
 // data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: any;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateY(0)' })),
      transition('void => *', [
        style({ transform: 'translateY(-100%)' }),
        animate(750),
      ]),
      transition('* => void', [
        // animate(500, style({ transform: 'translateY(100%)' }))
        style({ transform: 'translateY(-100%)' }),
        animate(500),
      ])
    ])
  ]
})
export class DashboardComponent implements OnInit {
 /* WidgetlineChart: Chart = {
    type: 'Line', data: data['WidgetlineChart2'],
    options: {
      axisX: {
        showGrid: false,
        showLabel: false,
        offset: 0,
      },
      axisY: {
        showGrid: false,
        low: 42,
        showLabel: false,
        offset: 0,
      },
      fullWidth: true
    },
  };*/

  domainInfo: DomainInfoView;
  TOKEN_ROLES: string[] = [];

  constructor(private parametreService: ParametreService,
    private bankaOnlineService: BankaOnlineService,
    private authService: AuthService
  ) { }



  ngOnInit() {
    this.getDomainInfo();
    this.getParamtrinkChartBilgileri();
    this.getBankaOnlineStatisticsForChart();
  }


  paramtrinkVeri: OdemeVeriView = new OdemeVeriView();
  getParamtrinkChartBilgileri() {
    this.parametreService.getOdemeForChart().subscribe(data => {
      if (data) {
        this.paramtrinkVeri = data;
      }
    }, error => {

    });
  }


  bankaStatistics: BankaOnlineStatisticsChartView = new BankaOnlineStatisticsChartView();
  getBankaOnlineStatisticsForChart() {
    this.bankaOnlineService.getBankaOnlineStatisticsForChart().subscribe(data => {
      this.bankaStatistics = data;
    }, error => {

    });
  }

  bankaOnlineModuleVarmi() {
    return this.domainInfo.modules.find(x => x.type == ModuleType.BankaOnline);
  }

  isAuthorizedForBankaOnline() {
    return this.TOKEN_ROLES.includes("Owner") || this.TOKEN_ROLES.includes("Admin") || this.TOKEN_ROLES.includes("System") ||
      this.TOKEN_ROLES.includes("BankaOnline_Admin");
  }

  isAuthorizedForSanalPos() {
    return this.TOKEN_ROLES.includes("Owner") || this.TOKEN_ROLES.includes("Admin") || this.TOKEN_ROLES.includes("System") ||
      this.TOKEN_ROLES.includes("SanalPos_Admin");
  }

  getDomainInfo() {
    this.authService.GetDomainInfo().subscribe(data => {
      this.domainInfo = data;
    });

    this.TOKEN_ROLES = this.authService.getCurrentUserRoles();
  }
}
