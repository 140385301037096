import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ForgotPasswordView } from 'app/model/ForgotPasswordView';
import { AlertifyService } from 'app/services/alertify.service';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-forgotPassword',
  templateUrl: './forgotPassword.component.html',
  styleUrls: ['./forgotPassword.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private alertifyService: AlertifyService) { }

  @Output() handleLogin = new EventEmitter();
  @Output() handleRegister = new EventEmitter();

  ngOnInit() {
    this.createForm();
  }

  forgotPasswordView: ForgotPasswordView = new ForgotPasswordView();
  form: FormGroup;

  createForm() {
    this.form = this.formBuilder.group({
      userName: ['', Validators.required],
      phone: ['', Validators.required]
    });
  }

  onSubmit() {
    this.authService.forgotPasswordForApplication(this.forgotPasswordView).subscribe(data => {
      this.alertifyService.success("Şifreniz telefonunuza başarıyla gönderildi.");
      this.handleLogin.emit();
    });
  }

  onLogin() {
    this.handleLogin.emit();
  }

  onRegister() {
    this.handleRegister.emit();
  }



}
