<form [formGroup]='userInfoForm' class="form form-horizontal striped-rows form-bordered" >
  <div class="form-body">
      <div class="form-group row">
        <label class="col-md-3 label-control" for="username">Kullanıcı Adı:</label>
        <div class="col-md-9">
        <input type="username" id="username" formControlName="username" class="form-control"  [(ngModel)]="userInfo.username" autofocus>
        </div>
        <label class="col-md-3 label-control" for="name">Ad Soyad:</label>
        <div class="col-md-9">
        <input type="name" id="name" formControlName="name" class="form-control" [(ngModel)]="userInfo.name" autofocus>
        </div>
        <label class="col-md-3 label-control" for="email">E-mail:</label>
        <div class="col-md-9">
        <input type="email" id="email" formControlName="email" class="form-control" [(ngModel)]="userInfo.email" autofocus>
        </div>
        <label class="col-md-3 label-control" for="cep">Cep Telefonu:</label>
        <div class="col-md-9">
        <input type="cep" id="cep" formControlName="cep" class="form-control" [(ngModel)]="userInfo.cep" autofocus>
        </div>
        <label class="col-md-3 label-control" for="tcKimlik">Tc. Kimlik No:</label>
        <div class="col-md-9">
        <input type="tcKimlik" id="tcKimlik" formControlName="tcKimlik" class="form-control" [(ngModel)]="userInfo.tcKimlik" autofocus>
        </div>
      </div>
  </div>

  </form>

  