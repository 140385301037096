<app-loadingScreen *ngIf="!domainInfo"></app-loadingScreen>
<div *ngIf="domainInfo">
  <div *ngIf="!moduleVarmi()">
    <app-buyModule  [moduleAdi]="moduleAdi"></app-buyModule>
  </div>
  <div *ngIf="moduleVarmi()">

    <ng-template [ngIf]="!isAuthorized()">
      <app-accessDenied></app-accessDenied>
    </ng-template>

    <ng-template [ngIf]="isAuthorized()">
      <tbm-Kullanicilar (eventBind)="eventAlert($any($event))" (kullaniciEkle)="eventKullaniciEkle()"
        (kullaniciYetkilendirme)="eventUserRolesModal($any($event))" [loadKullanicilarList]="loadKullanicilarList">
      </tbm-Kullanicilar>
    </ng-template>


  </div>
</div>

<ng-template #contentYetkiDuzenle let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Yetki Düzenle</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <tbm-KullaniciYetkilendirme (eventBind)="eventAlert($any($event))" (eventCloseModal)="eventCloseModal()"
      [tokenRoles]="TOKEN_ROLES" [userId]="userId">
    </tbm-KullaniciYetkilendirme>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Kapat</button>
  </div>
</ng-template>
