import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BildirimService {

  constructor(private httpClient: HttpClient,
  ) { }

  path = environment.bildirimPath + "api/bildirim/";
  private getHttp<T>(functionName: string): Observable<T> {
    return this.httpClient.get<T>(this.path + functionName);
  }
  private postHttp<T>(functionName: string, param: any): Observable<T> {
    return this.httpClient.post<T>(this.path + functionName, param);
  }

  loginControl(): Observable<boolean> {
    return this.getHttp("loginControl");
  }


}
