import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { DomainInfoView } from 'app/model/DomainInfoView';
import { ModuleType } from 'app/model/ModuleType';

@Component({
  selector: 'app-SmsHesapBilgisi',
  templateUrl: './SmsHesapBilgisi.component.html',
  styleUrls: ['./SmsHesapBilgisi.component.css']
})
export class SmsHesapBilgisiComponent implements OnInit {

  constructor(private authService: AuthService) { }

  domainInfo: DomainInfoView;
  moduleAdi: string = "SMS";
  TOKEN_ROLES: string[] = [];
  ngOnInit() {
    this.authService.GetDomainInfo().subscribe(data => {
      this.domainInfo = data;
    });
    this.TOKEN_ROLES = this.authService.getCurrentUserRoles();
  }
  smsVarmi() {
    return this.domainInfo.modules.find(x => x.type == ModuleType.Iletisim);
  }

  isAuthorized() {
    return this.TOKEN_ROLES.includes("Owner") || this.TOKEN_ROLES.includes("Admin") || this.TOKEN_ROLES.includes("System");
  }
}
