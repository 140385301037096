export enum ModuleType {
  TemelIslemler = 0, //herkese verilen register olabilme, kendi bilgisini düzenleme gibi işlemler

  WebSitesi = 1,
  E_Sinav = 2,
  SanalPos = 3,
  Iletisim = 4,
  Soru_Bankasi = 5,
  SurucuETL = 6,
  SurucuKursu = 7,

  SeffafYonetim = 8, //Bağımsız bölüm - üye işlemleri  gibi işlemler şeffaf yönetim işlemlerini kapsar
  SeffafV2 = 98,

  WebSayfasi = 9, // Şeffaf Yönetim Uygulaması olan kullanıcılara daha kısıtlı olarak verilen web modülüdür
  Personel = 11, //Personelleri hakkında bilgilerin tutulduğu kart işlemleri
  InsanKaynaklari = 12, //Personel işe başlama ayrılma, izin takibi gibi, performans gibi işlemler
  Puantaj = 13,  //işe giriş çıkış, mesai gibi işlemlerin günlük takibi
  Bordro = 14, //Maaş hesabı ve bildirimler - Puantaj modülü varsa bordro bilgileri puantajdan alır yoksa puantaj bilgileri bordroya elle girilir.
  OnMuhasebe = 15, //Cari kartlar ödeme işlemleri gibi işlemler
  UyeTakip = 16,
  BankaOnline = 17,

  SistemCore = 99 //istemin çekirdek işlemleri
}
