<div class="container-fluid">
  <div class="row">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card border-grey border-lighten-3 px-1 py-1 box-shadow-3">
        <div class="card-block">
          <span class="card-title text-center">
            <img alt="avtar" class="img-fluid mx-auto d-block pt-2" src="assets/image/logo/paramtrink-logo-dark.png"
              width="350">
          </span>
        </div>
        <div class="card-block text-center">
          <h3>Bu sayfaya erişim yetkiniz yok.</h3>
          <p class="lead">Kurum yetkiliniz ile iletişime geçerek yetki alabilirsiniz.</p>
          <div class="mt-2"><i class="ft-alert-circle font-large-2"></i></div>
        </div>
      </div>
    </div>
  </div>
</div>
