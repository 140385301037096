<ng-template [ngIf]="!isAuthorized()">
  <app-accessDenied></app-accessDenied>
</ng-template>

<ng-template [ngIf]="isAuthorized()">
  <tbm-kurumBilgilerim [domainName]="domainName" [loadSayfa]="loadSayfa" (adresim)="adresDuzenle($any($event))"
    (iletisimim)="iletisimDuzenle($any($event))" (subem)="subeDuzenle($any($event))" (kurumum)="kurumDuzenle($any($event))"
    (resimSubem)="resimDuzenle($any($event))" (eventBind)="eventAlert($any($event))"></tbm-kurumBilgilerim>
</ng-template>





<ng-template #contentAdres let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Adres Bilgilerini Düzenle</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <tbm-adresBilgilerim [adresId]="adresId" [subeId]="subeId" [domainId]="domainId" (eventBind)="eventAlert($any($event))">
    </tbm-adresBilgilerim>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Kapat</button>
  </div>
</ng-template>
<ng-template #contentIletisim let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">İletişim Bilgilerini Düzenle</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <tbm-iletisimBilgilerim [iletisimId]="iletisimId" [subeId]="subeId" [domainId]="domainId"
      (eventBind)="eventAlert($any($event))"></tbm-iletisimBilgilerim>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Kapat</button>
  </div>
</ng-template>
<ng-template #contentSube let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Şube Bilgilerini Düzenle</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <tbm-subeBilgilerim [subeId]="subeId" [kurumId]="kurumId" [domainId]="domainId" (eventBind)="eventAlert($any($event))">
    </tbm-subeBilgilerim>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Kapat</button>
  </div>
</ng-template>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Kurum İşlemleri</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <tbm-kurumEkle [domainName]="domainName" [kurumId]="0" (alertEvent)="eventAlert($any($event))" [kendiKurumum]="kendiKurumum">
    </tbm-kurumEkle>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Kapat</button>
  </div>
</ng-template>
<ng-template #contentResim let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Şube Resmi Düzenle</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <tbm-subeResmi [subeId]="subeId" [kurumId]="kurumId" [domainId]="domainId" (eventBind)="eventAlert($any($event))">
    </tbm-subeResmi>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('')">Kapat</button>
  </div>
</ng-template>
