import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { DomainInfoView } from 'app/model/DomainInfoView';
import { ModuleType } from 'app/model/ModuleType';
import { AlertifyService } from 'app/services/alertify.service';
import { AlertModel } from 'app/model/AlertModel';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-Kullanicilar',
  templateUrl: './Kullanicilar.component.html',
  styleUrls: ['./Kullanicilar.component.css']
})

export class KullanicilarComponent implements OnInit {

  metin: string = "Temel İşlemler modülünü kullanma hakkınız yoktur.Bilgi için Tabim Bilişim'i arayınız.";
  etiket: string = "webSayfasi";
  domainInfo: DomainInfoView;



  constructor(
    private authService: AuthService,
    private alertifyService: AlertifyService,
    private router: Router,
    private modalService: NgbModal
  ) { }


  @ViewChild('contentYetkiDuzenle') contentYetkiDuzenle: ModalDirective;
  modalRef: NgbModalRef;
  TOKEN_ROLES: string[] = [];
  userId: number;
  loadKullanicilarList: boolean = false;
  moduleAdi: string = "Temel İşlemler";

  ngOnInit() {
    this.authService.GetDomainInfo().subscribe(data => {
      this.domainInfo = data;
    })

    this.TOKEN_ROLES = this.authService.getCurrentUserRoles();
  }

  moduleVarmi() {
    return this.domainInfo.modules.find(x => x.type == ModuleType.TemelIslemler);
  }

  eventAlert(data: AlertModel) {
    if (data.status == true) {
      if (data.eventType == 1) {
        this.alertifyService.success("İşleminiz Kayıt Edilmiştir");
      } else if (data.eventType == 3) {
        this.alertifyService.success("İşleminiz Güncellenmiştir");
      } else if (data.eventType == 2) {
        this.alertifyService.success("İşleminiz Silinmiştir");
      } else if (data.eventType == 4) {
        this.alertifyService.success("İşleminiz yapılmıştır");
      }
    } else {
      if (data.eventType == 1) {
        this.alertifyService.error("İşleminiz Kayıt Edilememiştir");
      } else if (data.eventType == 3) {
        this.alertifyService.error("İşleminiz Güncellenmemştir");
      } else if (data.eventType == 2) {
        this.alertifyService.error("İşleminiz Silenememiştir");
      } else if (data.eventType == 4) {
        this.alertifyService.error("İşleminiz yapılamamıştır");
      }
    }
  }

  eventKullaniciEkle() {
    this.router.navigateByUrl("/kullaniciEkle");
  }

  eventUserRolesModal(userId: number) {
    this.modalRef = this.modalService.open(this.contentYetkiDuzenle, { size: "lg" });
    this.userId = userId;
    this.loadKullanicilarList = false;
  }

  eventCloseModal() {
    this.modalRef.close();
    this.loadKullanicilarList = true;
  }

  isAuthorized() {
    return this.TOKEN_ROLES.includes("Owner") || this.TOKEN_ROLES.includes("Admin") || this.TOKEN_ROLES.includes("System");
  }




}
