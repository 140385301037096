<section id="forgot-password">
  <div class="container-fluid">
    <div class="row text-left full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card bg-blue-grey bg-darken-3 px-4 width-400">
          <div class="card-header">
            <div class="card-image text-center">
              <i class="icon-key font-large-5 blue-grey lighten-4"></i>
            </div>
          </div>
          <div class="card-body">
            <div class="card-block">
              <div class="text-center">
                <h4 class="text-uppercase text-bold-400 white">Şifremi Unuttum</h4>
              </div>
              <form class="pt-4" (ngSubmit)="onSubmit()" [formGroup]="form">
                <div class="form-group">
                  <label for="userName">Kullanıcı Adı</label>
                  <input type="text" class="form-control" formControlName="userName" name="userName" id="userName"
                    placeholder="Kullanıcı adınız" [(ngModel)]="forgotPasswordView.userName">
                  <div *ngIf="form.get('userName').invalid && form.get('userName').touched">
                    <div class="validate-error" *ngIf="form.get('userName').hasError('required')">
                      Kullanıcı adı zorunludur.
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="phone">Cep Telefonu</label>
                  <input type="text" class="form-control" formControlName="phone" name="phone" id="phone" prefix="0"
                    mask="(000) 000 00 00" [(ngModel)]="forgotPasswordView.phone">
                  <div *ngIf="form.get('phone').invalid && form.get('phone').touched">
                    <div class="validate-error" *ngIf="form.get('phone').hasError('required')">
                      Telefon numarası zorunludur.
                    </div>
                  </div>
                </div>
                <div class="form-group pt-2">
                  <div class="text-center mt-3">
                    <button type="submit" class="btn btn-danger btn-raised btn-block"
                      [disabled]="!form.valid">Gönder</button>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-footer bg-blue-grey bg-darken-3">
              <div class="float-left white"><a (click)="onLogin()">Giriş Yap</a></div>
              <div class="float-right white"><a (click)="onRegister()">Kaydol</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
