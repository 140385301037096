export class OdemeTalep {
    TalepNo: string;
    IslemTutari: number = 0;
    Aciklama: string;
    EkstreBilgi: string;
    appId: string;
    appKey: string;
    DomainId:number = 0;
    UserId:number = 0;
    MusteriAdi:string;
    Degistiremesin:boolean;
    TaksitSayisi:number;
    NetMi:boolean;
}
