import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ParametreService } from '../services/parametre.service';
import { ApplicationAccount } from "../model/ApplicationAccount";
import { Observable} from 'rxjs';
import { AuthService } from "../services/auth.service";

@Injectable()
export class ParametreResolve implements Resolve<ApplicationAccount > {

  public applicationAccount: ApplicationAccount;
  constructor(private parametreService: ParametreService,private authService: AuthService) { }

  get isAuthenticated() {
    let lg = this.authService.loggedIn();
    return lg;
  }
  resolve():Observable<ApplicationAccount>{
    if(this.isAuthenticated){
      return this.parametreService.getApplicationAccount();
    }
  }
}
