import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { ApplicationAccount } from "../model/ApplicationAccount";
import { OdemeVeriView } from "../model/OdemeVeriView";
import { AuthService } from "./auth.service";
import { OdemeHazirlaAppView } from "../model/OdemeHazirlaAppView";
import { OdemeIstekView } from "../model/OdemeIstekView";

@Injectable({
  providedIn: "root"
})
export class ParametreService {
  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  getApplicationAccount(): Observable<ApplicationAccount> {
    return this.getHttp<ApplicationAccount>("Hesap/GetSanalPosApplicationAccount");
  }
  private getHttp<T>(functionName: string): Observable<T> {
    return this.httpClient.get<T>(environment.paramtrinkPath + functionName);
  }

  private postHttp<T>(functionName: string, param: any): Observable<T> {
    return this.httpClient.post<T>(environment.paramtrinkPath + functionName, param);
  }

  getOdemeForChart(): Observable<OdemeVeriView> {
    return this.getHttp("Odeme/getOdemeForChart");
  }

  OdemeHazirlaAppService(odeme: OdemeHazirlaAppView): Observable<OdemeIstekView> {
    return this.postHttp("Odeme/OdemeHazirlaAppService", odeme);
  }

}
