import { Injectable, ModuleWithProviders, Optional } from "@angular/core";
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpRequest,
  HttpEvent
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { AlertifyService } from "./alertify.service";
import { environment } from "environments/environment";
import { Kurum } from "../model/Kurum";
import { Sube } from "app/model/Sube";
import { FileUploader } from "ng2-file-upload";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class WebSiteService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private alertifyService: AlertifyService,
    private authService:AuthService
  ) {}

  path = environment.corePath + "api/WebSite/";
  rootPath=environment.corePath;


  private getHttp<T>(functionName: string): Observable<T> {
    return this.httpClient.get<T>(this.path + functionName);
  }
  private postHttp<T>(functionName: string, param: any): Observable<T> {
    return this.httpClient.post<T>(this.path + functionName,param);
  }





  public gorunumler = Array<string>();
  getYayinGorunumleri() {
   /* if (this.gorunumler.length == 0) {
      this.httpClient
        .get<string[]>(this.path + "getYayinListeGorunumleri")
        .toPromise()
        .then(res => {
          this.gorunumler = res;
        });
    } */
  }

  /*  async deleteMenu(item): Promise<boolean> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    this.httpClient
      .post(this.path + "deleteMenu", item, { headers: headers })
      .subscribe(data => {
        this.alertifyService.success("Menü listeden çıkarıldı!");
      });
    return item;
  }*/

  deleteMenu(item): Observable<boolean> {
    return this.postHttp("deleteMenu", item);
  }

  //KURUM İŞLEMLERİ
  getKurumListesi(): Observable<Kurum[]> {
    return this.getHttp("getKurumListesi");
  }
  getKurum(): Observable<Kurum> {
    return this.getHttp("getKurum");
  }
  deleteKurum(item): Observable<boolean> {
    return this.postHttp("deleteKurum", item);
  }
  async setKurum(kurum): Promise<Kurum> {
    this.postHttp("postKurum", kurum).subscribe(data => {
      this.alertifyService.success("Kurum tanımı başarıyla eklendi!");
      kurum = data;
    });
    return kurum;
  }
  setSube(sube): Observable<Sube> {
    return this.postHttp<Sube>("postSube", sube);
  }
  deleteSube(item): Observable<boolean> {
    return this.postHttp("deleteSube", item);
  }
  fileUpload():FileUploader
  {
  let upload=  new FileUploader({
      url:  this.path + "kurumLogoUpload",
      isHTML5: true,
      allowedFileType: ['image'],
      autoUpload: true,
      removeAfterUpload: true,
      maxFileSize: 10 * 1024 * 1024,
      method: 'POST'
    });
    return upload;
  }
  isDomainModule(moduleType):Observable<boolean> {
    return this.getHttp<boolean>("isDomainModule/?moduleType=" + moduleType);
  }
}
