export class DomainWebParametre {
    yayinda: boolean;
    tasarimId: boolean;
    kullaniciGirebilsinMi: boolean;
    sosyalMedyaGirisiAktifMi: boolean;
    sosyalMedyaPaylasimiAktifMi: boolean;
    yazarBilgisiGirilsinMi: boolean = false;
    googleSiteVerification: string;
    metaTag: string;
    domainId: number;
    domain: string;
    id: number;
    rowStatus: number;
    operationDate: Date;
    operatingUserId: number;
    rowVersion: string;

}