import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bankaSorguListesi',
  templateUrl: './bankaSorguListesi.component.html',
  styleUrls: ['./bankaSorguListesi.component.css']
})
export class BankaSorguListesiComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) { }

  bankaOnlineHesapId: number = 0;

  ngOnInit() {
    this.getActivatedRoute();
  }

  getActivatedRoute() {
    this.activatedRoute.params.subscribe(param => {
      this.bankaOnlineHesapId = param["bankaOnlineHesapId"];
    });
  }

}
