<section class="horizontal-grid">
  <div  id="iframe_holder" class="center-block" data-mediator="payment-form-loader">
      <iframe #iframe  e id="load_payment" class="embed-responsive-item" name="load_payment" frameborder="0" scrolling="no"  height="1200px" width="100%" >
      </iframe>
  </div>
  <form  #send_hptoken  id="send_hptoken" [action]=odemePath  method="post" target="load_payment">
      <input type="hidden" name="TalepNo" [value]="odemeTalep.TalepNo" />
      <input type="hidden" name="IslemTutari" [value]="odemeTalep.IslemTutari" />
      <input type="hidden" name="AppId" [value]="odemeTalep.appId" />
      <input type="hidden" name="AppKey" [value] ="odemeTalep.appKey" />
      <input type="hidden" name="UserId" [value]="odemeTalep.UserId" />
      <input type="hidden" name="DomainId" [value]="odemeTalep.DomainId" />
      <input type="hidden" name="Aciklama" [value]="odemeTalep.Aciklama" />
      <input type="hidden" name="MusteriAdi" [value]="odemeTalep.MusteriAdi" />
      <input type="hidden" name="Degistiremesin" [value]="odemeTalep.Degistiremesin" />
      <input type="hidden" name="TaksitSayisi" [value]="odemeTalep.TaksitSayisi" />
      <input type="hidden" name="NetMi" [value]="odemeTalep.NetMi" />
    </form>
